import * as React from "react";

const SvgCalendarPrimary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    {...props}
  >
    <path d="M19.5 2.364h3.541A2.958 2.958 0 0 1 26 5.323v17.718A2.957 2.957 0 0 1 23.041 26H2.959A2.958 2.958 0 0 1 0 23.041V5.322a2.957 2.957 0 0 1 2.959-2.958H6.5V1.182a1.182 1.182 0 0 1 2.364 0v1.182h8.273V1.182a1.182 1.182 0 0 1 2.364 0Zm0 2.364v1.181a1.182 1.182 0 0 1-2.364 0V4.727H8.864v1.182a1.182 1.182 0 0 1-2.364 0V4.727H2.959a.594.594 0 0 0-.6.6v17.714a.594.594 0 0 0 .6.6h20.082a.594.594 0 0 0 .6-.6V5.322a.594.594 0 0 0-.6-.6ZM13 14.182a1.773 1.773 0 1 1 1.773-1.773A1.773 1.773 0 0 1 13 14.182Zm6.5 0a1.773 1.773 0 1 1 1.773-1.773 1.773 1.773 0 0 1-1.773 1.773Zm-13 6.5a1.773 1.773 0 1 1 1.773-1.773A1.773 1.773 0 0 1 6.5 20.682Zm6.5 0a1.773 1.773 0 1 1 1.773-1.773A1.773 1.773 0 0 1 13 20.682Z" />
  </svg>
);

export default SvgCalendarPrimary;
