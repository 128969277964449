import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import CurrencyFormat from 'react-currency-format';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
// import GradeIcon from '@material-ui/icons/Grade';
import PlaceIcon from '@material-ui/icons/Place';
// import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

// import { format, parseISO } from 'date-fns';
import { ThemeButton } from '../../../ThemeButton/styles';
import { useGlobal } from '../../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../../utils/addFeeByDestinoFerias';

const HotelCard = ({ hotel, searchState, buscaAtual }) => {
	const { user, signed } = useGlobal();
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	let history = useHistory();
	// const finalValue = hotel.price.amountBeforeTax;
	const nights =
		(new Date(hotel.availability.end) - new Date(hotel.availability.start)) /
		(1000 * 3600 * 24);
	// const percDiscount =
	//   ((hotel.price.amountAfterTax - hotel.price.amountBeforeTax) /
	//     hotel.price.amountAfterTax) *
	//   100;

	// const stars = Array(Math.round(hotel.basicPropertyInfo?.award?.rating))
	// 	.fill()
	// 	.map((_, idx) => Math.round(hotel.basicPropertyInfo?.award?.rating) + idx);

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<div>
			<S.Wrapper>
				<S.CardContent container>
					<S.HotelDetails item xs={12}>
						<img src={hotel.basicPropertyInfo.imageURL} alt='imagem do hotel' />
						<div className='content'>
							<div className='stars'>
								<Stack spacing={1}>
									<Rating
										name='half-rating-read'
										value={hotel.basicPropertyInfo?.award?.rating}
										precision={0.5}
										readOnly
									/>
								</Stack>
								{/* {stars.map((star, index) => (
									<GradeIcon fontSize='small' key={index} />
								))} */}
							</div>
							<div className='hotel-details'>
								<span className='hotel-name'>
									{hotel.basicPropertyInfo.hotelRef.hotelName}
								</span>
								<div>
									{/* <span className='hotel-rate'>{7.9}</span> */}
									<span className='hotel-rate-level'>{/* {'Bom'} ({120}) */}</span>
								</div>
							</div>
							{hotel?.basicPropertyInfo.address.addressLine && (
								<div className='location'>
									<span>
										<PlaceIcon fontSize='small' />
										{hotel.basicPropertyInfo.address.addressLine}
									</span>
									{/* <span>
                  <DirectionsWalkIcon fontSize='small' />
                  {0.46}km de distância até o centro da cidade
                </span> */}
								</div>
							)}
						</div>
					</S.HotelDetails>
					<S.BottomDetails item xs={12}>
						<div>
							{/* {hotel.price.amountAfterTax !== hotel.price.amountBeforeTax && (
                <span className='old-value'>
                  De{' '}
                  <CurrencyFormat
                    fixedDecimalScale={true}
                    className='value'
                    value={hotel.price.amountAfterTax}
                    displayType={'text'}
                    decimalSeparator=','
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </span>
              )} */}
							<span className='final-value'>
								{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
									<span>
										{getValuesByUser(
											hotel.roomStays.roomTypes.sort((a, b) => {
												if (
													a.roomRates[0]?.[
														a.roomRates[0]?.total.currencyCode === 'BRL'
															? 'total'
															: 'origTotal' || 'total'
													].amountAfterTax >
													b.roomRates[0]?.[
														b.roomRates[0]?.total.currencyCode === 'BRL'
															? 'total'
															: 'origTotal' || 'total'
													].amountAfterTax
												) {
													return 1;
												}
												if (
													a.roomRates[0]?.[
														a.roomRates[0]?.total.currencyCode === 'BRL'
															? 'total'
															: 'origTotal' || 'total'
													].amountAfterTax <
													b.roomRates[0]?.[
														b.roomRates[0]?.total.currencyCode === 'BRL'
															? 'total'
															: 'origTotal' || 'total'
													].amountAfterTax
												) {
													return -1;
												}
												return 0;
											})[0]?.roomRates[0]?.[
												hotel.roomStays.roomTypes[0]?.roomRates[0].total.currencyCode ===
												'BRL'
													? 'total'
													: 'origTotal' || 'total'
											].amountAfterTax
										)}{' '}
										{shortenCoinAlias}
									</span>
								) : (
									<>
										<CurrencyFormat
											fixedDecimalScale={true}
											value={
												hotel.roomStays.roomTypes[0].roomRates[0][
													hotel.roomStays.roomTypes[0].roomRates[0].total.currencyCode ===
													'BRL'
														? 'total'
														: 'origTotal' || 'total'
												].amountAfterTax
											}
											displayType={'text'}
											decimalSeparator=','
											decimalScale={2}
											prefix={'R$ '}
										/>
									</>
								)}
							</span>
							<div className='nights'>
								<span className='qty'>
									por {nights} noite(s) x {searchState.qtRooms} quarto(s)
								</span>
								<span className='daily-value'>
									{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
										<span>
											{getValuesByUser(
												hotel.roomStays.roomTypes[0].roomRates[0][
													hotel.roomStays.roomTypes[0].roomRates[0].total.currencyCode ===
													'BRL'
														? 'total'
														: 'origTotal' || 'total'
												].amountAfterTax / nights
											)}{' '}
											{shortenCoinAlias}
										</span>
									) : (
										<>
											<CurrencyFormat
												fixedDecimalScale={true}
												value={
													hotel.roomStays.roomTypes[0].roomRates[0][
														hotel.roomStays.roomTypes[0].roomRates[0].total.currencyCode ===
														'BRL'
															? 'total'
															: 'origTotal' || 'total'
													].amountAfterTax / nights
												}
												displayType={'text'}
												decimalSeparator=','
												decimalScale={2}
												prefix={'R$ '}
											/>
											/diária
										</>
									)}
								</span>
							</div>
							{/* {hotel.price.amountAfterTax !== hotel.price.amountBeforeTax && (
                <span className='discount'>
                  -
                  <CurrencyFormat
                    fixedDecimalScale={true}
                    value={percDiscount}
                    displayType={'text'}
                    decimalSeparator=','
                    decimalScale={2}
                    suffix='%'
                  />
                </span>
              )} */}

							<ThemeButton
								onClick={() => {
									sessionStorage.setItem('@hotelSelected', JSON.stringify(hotel));
									history.push('/busca/hotel-details');
								}}
								className='button-select'
							>
								Detalhes
							</ThemeButton>
						</div>
					</S.BottomDetails>
				</S.CardContent>
			</S.Wrapper>
		</div>
	);
};

export default HotelCard;
