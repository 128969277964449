import * as S from "./styles";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

import BusSegmentOption from "./BusSegmentOption/";
import { ThemeButton } from "../../ThemeButton/styles";
import { useState } from "react";
import ReprocessarPagamento from "../../ReprocessarPagamento";
import BookingsRepository from "../../../repositories/bookingsRepository";
import { useGlobal } from "../../../contexts/globalContext";
import Loader from "../../Loader";
import { useHistory } from "react-router-dom";
import Auth2faModal from "../../Auth2FaModal";

const BusTravelsCard = ({ booking = [], coinAlias }) => {
  let history = useHistory();

  const bookingsRepository = new BookingsRepository();
  const { showAlert } = useGlobal();

  const [openDialogReprocessar, setOpenDialogReprocessar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [openModal2fa, setOpenModal2fa] = useState(false);
  const closeModal2fa = () => setOpenModal2fa(false);

  const onChangeOpenDialogReprocessar = (open) =>
    setOpenDialogReprocessar(open);

  const getStatusTranslate = (status) => {
    switch (status) {
      case 1:
        return "pendente";
      case 2:
        return "emitido";
      case 3:
        return "expirado";
      case 4:
        return "finalizado";
      case 5:
        return "cancelado";
      default:
        break;
    }
  };

  const reprocessar = () => {
    if (
      (booking.paymentTransaction === null ||
        booking.paymentTransaction === undefined) &&
      booking.cashTotalValue > 0
    ) {
      onChangeOpenDialogReprocessar(true);
    } else {
      setOpenLoader(true);
      bookingsRepository
        .reprocessarPagamento(booking.bookingCode, {})
        .then((res) => {
          setOpenLoader(false);
          sessionStorage.setItem("@bookingCompleted", JSON.stringify(res));
          switch (res.status) {
            case 200:
              history.push("/busca/finalizado");
              break;
            case 201:
              if (res.data.auth2fa) {
                if (res.data.redirectAuth2fa !== null) {
                  setOpenLoader(true);
                  window.location.replace(res.data.redirectAuth2fa);
                } else {
                  setOpenModal2fa(true);
                }
              } else {
                history.push("/busca/finalizado");
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          setOpenLoader(false);
          switch (err?.response?.status) {
            case 400:
              showAlert("Há algo de errado nos seus dados, verifique!");
              break;
            case 401:
              history.push("/session-expires");
              break;
            case 409:
              sessionStorage.setItem(
                "@bookingError",
                JSON.stringify(err.response.data.error)
              );
              sessionStorage.setItem(
                "reprocessar",
                JSON.stringify({ reprocessar: true })
              );
              history.push("/busca/booking-error");
              break;
            case 504:
              sessionStorage.setItem(
                "@bookingError",
                JSON.stringify("Tempo expirou, tente novamente!")
              );
              sessionStorage.setItem(
                "reprocessar",
                JSON.stringify({ reprocessar: true })
              );
              history.push("/busca/booking-error");
              break;
            default:
              showAlert(
                "error",
                `Compra não processada, por favor, tente novamente!`
              );
              break;
          }
        });
    }
  };

  const reenviarVoucher = (locator) => {
    bookingsRepository
      .reenviarVoucher(locator)
      .then((res) =>
        showAlert("success", "Voucher reenviado, verifique seu e-mail!")
      )
      .catch((err) => showAlert("error", "Houve algum erro, tente novamente!"));
  };

  return booking ? (
    <S.BlankCard>
      <ReprocessarPagamento
        open={openDialogReprocessar}
        onClose={onChangeOpenDialogReprocessar}
        booking={booking}
      />
      <Loader openLoader={openLoader} />
      <Auth2faModal
        open={openModal2fa}
        onClose={closeModal2fa}
        booking={{ data: booking }}
        type="reprocessar"
      />
      <S.CardHeader>
        <S.Title>
          Localizador: {booking?.locator}
          <span className="subtitle">
            Data da Reserva{" "}
            {booking?.bookingDateStr !== undefined &&
              booking?.bookingDateStr !== "Invalid Date" &&
              format(parseISO(booking?.bookingDateStr), "dd/MM/yyyy", {
                locale: pt,
              })}
          </span>
        </S.Title>
        {booking.bookingService.status && (
          <S.StatusContainer>
            {booking.bookingService.statusId === 1 && (
              <ThemeButton className="reprocessar" onClick={reprocessar}>
                Reprocessar Pagamento
              </ThemeButton>
            )}
            {booking.bookingService.statusId === 2 && (
              <ThemeButton
                className="reprocessar"
                onClick={() => reenviarVoucher(booking.bookingCode)}
              >
                Reenviar Voucher
              </ThemeButton>
            )}

            <S.Status
              className={`status ${getStatusTranslate(booking.statusId)}`}
            >
              {getStatusTranslate(booking.bookingService.statusId)}
            </S.Status>
          </S.StatusContainer>
        )}
      </S.CardHeader>

      <S.BusSummaryCardDetails>
        {Array.isArray(booking.bookingService) ? (
          booking.bookingService.map((segment) => (
            <BusSegmentOption
              bus={segment}
              classType={segment.bus.classType}
              company={segment.bus.company}
            />
          ))
        ) : (
          <BusSegmentOption
            bus={booking.bookingService}
            classType={booking.bookingService.bus.classType}
            company={booking.bookingService.bus.company}
          />
        )}
      </S.BusSummaryCardDetails>

      <S.BottomInfos>
        {booking.passengers && (
          <S.Travelers>
            <S.Subtitle>VIAJANTES</S.Subtitle>
            {booking.passengers.map((passenger, i) => (
              <S.TravelerName
                key={i}
              >{`${passenger.firstName} ${passenger.lastName}`}</S.TravelerName>
            ))}
          </S.Travelers>
        )}
        {!booking.pointsTotalValue && !booking.cashTotalValue && (
          <S.ValorTotal>
            Total: R${" "}
            {booking.BookingBySource[0].TotalFare.Total.toLocaleString("pt-BR")}
          </S.ValorTotal>
        )}
        {booking.pointsTotalValue ? (
          <S.ValorTotal>
            Valor em {coinAlias}: {booking.pointsTotalValue}
          </S.ValorTotal>
        ) : (
          ""
        )}
        {booking.cashTotalValue ? (
          <S.ValorTotal>
            {" "}
            Valor em R$: {booking.cashTotalValue.toLocaleString("pt-BR")}
          </S.ValorTotal>
        ) : (
          ""
        )}
      </S.BottomInfos>
      {booking.bookingExpirationDate && (
        <S.ExpirationDate>
          <S.Title>
            <span className="subtitle-locator">
              Protocolo: {booking.bookingCode}
            </span>
          </S.Title>
          {booking.purchaseId !== null && booking.purchaseId !== undefined && (
            <S.Title>
              <span className="subtitle-locator">ID: {booking.purchaseId}</span>
            </S.Title>
          )}
          {booking.statusId === 1 && (
            <span>
              Esta reserva expira em:{" "}
              {booking?.bookingExpirationDate !== undefined &&
                booking?.bookingExpirationDate !== "Invalid Date" &&
                format(
                  parseISO(booking?.bookingExpirationDate),
                  "dd/MM/yyyy HH:mm",
                  {
                    locale: pt,
                  }
                )}
            </span>
          )}
        </S.ExpirationDate>
      )}
    </S.BlankCard>
  ) : (
    <> </>
  );
};

export default BusTravelsCard;
