import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { Calendar } from 'react-date-range';

export const Modal = styled.div``;

export const Wrapper = styled.div`
	${({ theme }) => css`
		font-family: 'Poppins';
		margin-bottom: 5px;

		.spacing {
			margin-top: 10px;
		}

		.title {
			color: #3b3c40;
			font-size: 16px;
			font-weight: 600;
			height: 100%;
			width: 100%;
			letter-spacing: 0.08px;
			line-height: normal;
			white-space: wrap;
			vertical-align: middle;
			background-color: #fff;
			padding: 10px;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		}

		.calendar-container {
			.calendar {
				padding: 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				background-color: #fff;
				font-family: 'Poppins';
				width: 100%;
				height: 100%;
				border-radius: 4px;
				box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

				.top {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 100%;

					.price {
						color: ${theme.palette.primary.main};
						font-size: 22px;
						font-weight: 700;

						line-height: 26px;
					}

					.label1 {
						font-size: 15px;
						font-weight: 600;

						line-height: 22px;
						white-space: nowrap;
					}

					.label2 {
						color: #333;
						font-size: 12px;
						font-weight: 500;

						line-height: 22px;
						white-space: nowrap;
					}
				}

				.mid {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					justify-content: flex-end;
					margin-top: 10px;
					margin-bottom: 10px;

					.separator {
						border-bottom-style: dashed;
						border-bottom-width: 1px;
						border-color: #666666;
						position: absolute;

						.cancelation {
							color: #555555;
							font-size: 12px;
							font-weight: 600;
							letter-spacing: 0.08px;
							line-height: normal;
							white-space: nowrap;

							.label3 {
								opacity: 67%;
								color: #555;
								font-size: 12px;
								font-weight: 500;
								line-height: 22px;
								white-space: nowrap;
							}

							.tooltiptext {
								width: 200px;
								visibility: hidden;
								background-color: #f9f9f9;
								color: #333;
								text-align: left;
								border-radius: 8px;
								padding: 10px;
								position: absolute;
								z-index: 1;
								bottom: 150%;
								left: 20%;
								transform: translateX(-50%);
								box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
								opacity: 0;
								transition: opacity 0.3s ease;
								font-size: 0.9rem;
								line-height: 1.5;
								word-wrap: break-word;
								white-space: normal;
							}

							.tooltiptext h3 {
								font-size: 14px;
								margin: 0 0 5px 0;
								white-space: normal;
							}

							.tooltiptext p {
								font-size: 12px;
								margin: 0;
								white-space: normal;
							}

							.tooltiptext i {
								content: '';
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								border-width: 10px;
								border-style: solid;
								border-color: #f9f9f9 transparent transparent transparent;
							}

							&:hover .tooltiptext {
								visibility: visible;
								opacity: 1;
							}
						}
					}
				}

				.quantity-top {
					display: flex;
					flex-direction: row;
					align-items: center;
					width: 100%;
					position: relative;
					left: 10px;
					margin-bottom: 8px;

					.label1 {
						color: #333;
						font-size: 14px;
						font-weight: 600;
						height: 22px;
						line-height: 22px;
						white-space: nowrap;
					}

					.label2 {
						color: ${theme.palette.primary.main};
						font-size: 12px;
						font-weight: 400;
						line-height: 22px;
						white-space: nowrap;
					}
				}

				.bottom {
					display: flex;
					flex-direction: column;
					width: 100%;

					.passenger-item {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						margin-bottom: 8px;

						.label {
							color: #333;
							font-size: 14px;
							font-weight: 600;
							height: 22px;
							line-height: 22px;
							white-space: nowrap;
						}

						.counter {
							color: #333;
							font-size: 16px;
							font-weight: 700;
							height: 22px;
							line-height: 22px;
							white-space: nowrap;
							margin-left: 10px;
						}
					}
				}
			}
		}

		.about {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 20px;
			margin-right: 50px;

			.text {
				font-size: 14px;
				font-weight: 300;
				line-height: 22px;
				color: #797979;
				margin-right: 50px;
			}

			.tags {
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-right: 50px;
				height: 200px;
				width: 100%;
				border-top: 1px solid;
				border-bottom: 1px solid;
				border-color: #d7d7d7;

				img {
					width: 45px;
					height: 45px;
					margin-right: 20px;
				}

				.left,
				.right {
					display: flex;
					flex-direction: column;
					width: 48%;
				}

				.left div,
				.right div {
					display: flex;
					align-items: space-around;
					margin-top: 7px;
					margin-bottom: 7px;

					.info {
						flex-direction: column;
						align-items: flex-start;
						display: flex;
						justify-content: space-between;
						margin-top: 0px;
						margin-bottom: 0px;

						.top {
							color: ${theme.palette.primary.main};
							font-size: 14px;
							font-weight: 500;
							height: 18px;
							line-height: 22px;
							white-space: nowrap;
							letter-spacing: 0.1px;
						}

						.bottom {
							color: #555;
							font-size: 13px;
							font-weight: 400;
							height: 22px;
							line-height: 22px;
							white-space: wrap;
							letter-spacing: 0px;
						}
					}
				}

				.left div span,
				.right div span {
					display: inline-block;
				}
			}
		}

		.highlights {
			margin-bottom: 20px;
			margin-right: 50px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-bottom: 1px solid;
			border-color: #d7d7d7;

			.title {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}

			.highlights-grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
				width: 100%;
				margin-bottom: 20px;
			}

			.highlight-item {
				display: flex;
				align-items: center;
			}

			.highlight-item img {
				margin-right: 10px;
			}

			.highlight-item p {
				width: auto;
				margin: 0;
			}
		}

		.included {
			margin-bottom: 20px;
			margin-right: 50px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.title {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}

			.description {
				font-size: 14px;
				font-weight: 500;
				line-height: 22px;
				color: #555;
				height: 100%;
			}

			.list-container {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				width: 100%;
			}

			.includes,
			.excludes {
				width: 48%;
			}

			.item {
				display: flex;
				align-items: flex-start;
				margin-bottom: 10px;
			}

			.item img {
				margin-right: 10px;
				width: 20px;
				height: 20px;
			}
		}

		.box {
			background-color: #333;
			padding: 20px;
			border-radius: 8px;
			color: #fff;
			margin-bottom: 20px;
			margin-right: 50px;

			.title {
				font-size: 16px;
				font-weight: 400;
				color: #aaa;
				height: 28px;
				line-height: 28px;
				letter-spacing: 0.5px;
			}

			.highlight {
				color: ${theme.palette.primary.main};
			}

			.description {
				font-size: 13px;
				font-weight: 300;
				margin-bottom: 20px;
				color: #aaa;
				height: 44px;
				line-height: 22px;
				letter-spacing: 0px;
			}

			.items {
				display: flex;
				align-items: flex-start;
				margin-bottom: 10px;
				width: 100%;

				img {
					margin-right: 10px;
					width: 24px;
					height: 24px;
					flex-shrink: 0;
				}

				.item {
					color: #fff;
					font-size: 12px;
					font-weight: 500;
					line-height: 22px;
					margin-bottom: 20px;
					flex-grow: 1;
					white-space: pre-wrap;
					word-wrap: break-word;
				}
			}
		}
	`}
`;

export const GridFlexible = styled.div``;

export const Price = styled(Grid)`
	width: 100%;
	height: 70px;
	background: #fff;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;

export const Quantity = styled(Grid)`
	width: 100%;

	background: #fff;
	border: 1px solid #d5d5d5;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	.top {
		display: flex;
		align-items: center;
		padding: 0 10px;
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
	}
`;
export const DateCalendar = styled(Calendar)`
	${({ theme }) => css`
		width: 100%;
		background: #fff;
		border: 1px solid #d5d5d5;
		border-radius: 4px;

		.MuiInputBase-root {
			color: ${theme.palette.primary.main};
		}

		.rdrMonth,
		.rdrMonthsVertical {
			position: relative;
			background: #fff;
			width: 100%;
		}

		${theme.breakpoints.down('sm')} {
			zoom: 77%;
		}

		.rdrSelected,
		.rdrStartEdge,
		.rdrEndEdge {
			background-color: ${theme.palette.primary.main} !important;
			color: #fff !important;
			font-weight: 700 !important;
			font-size: 22px !important;
			border-radius: 0px !important;
			border: 4px solid ${theme.palette.primary.main} !important;
		}

		.rdrDayNumber span {
			color: #333 !important;
			font-weight: 400;
			font-size: 16px;
		}

		.rdrDayDisabled .rdrDayNumber span {
			color: #d3d3d3 !important;
			font-weight: 400;
			font-size: 16px;
		}

		.rdrDayStartPreview,
		.rdrDayInPreview,
		.rdrDayEndPreview {
			border: none;
			background: none;
		}

		.rdrDayHovered .rdrDayNumber span {
			background: none;
			border-radius: 0px;
		}
	`}
`;

export const ItemContent = styled(Grid)`
	width: 100%;
`;
export const CardContent = styled(Grid)`
	${({ theme }) => css`
		height: 100%;
		font-family: 'Poppins';
		color: #023566;
		max-width: 370px;
		display: flex;
		flex-direction: row;
		padding: 10px;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

		.container_img {
			margin: 10px;

			.img {
				height: 268px;
				max-width: 325px;
				border-radius: 10px;
			}
		}

		.container_details {
			position: relative;
			width: 95%;

			.top {
				width: 100%;

				.title {
					color: #3b3c40;
					font-size: 16px;
					font-weight: 600;
					height: 19px;
					left: 0;
					top: -1;
					letter-spacing: 0.08px;
					line-height: normal;
					white-space: wrap;
				}

				.type {
					color: ${theme.palette.primary.main};
					font-size: 12px;
					font-weight: 600;
					letter-spacing: 0.08px;
					line-height: normal;
				}

				.location-container {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}
				.location {
					color: #7f7f7f;
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.08px;
					line-height: normal;
				}
				.cancelation-container {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.separator {
						border-bottom-style: dashed;
						border-bottom-width: 1px;
						border-color: #666666;

						.cancelation {
							color: #008000;
							font-size: 12px;
							font-weight: 600;
							letter-spacing: 0.08px;
							line-height: normal;
							white-space: nowrap;

							.tooltiptext {
								width: 250px;
								visibility: hidden;
								background-color: #f9f9f9;
								color: #333;
								text-align: left;
								border-radius: 8px;
								padding: 10px;
								position: absolute;
								z-index: 1;
								border: 1px solid rgba(0, 0, 0, 0.1);
								left: 50%;
								transform: translateX(50%);
								box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
								opacity: 0;
								transition: opacity 0.3s ease;
								font-size: 0.9rem;
								line-height: 1.5;
								word-wrap: break-word;
								white-space: normal;
							}

							.tooltiptext h3 {
								font-size: 14px;
								margin: 0 0 5px 0;
								white-space: normal;
							}

							.tooltiptext p {
								font-size: 12px;
								margin: 0;
								white-space: normal;
							}

							.tooltiptext i {
								content: '';
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								border-width: 10px;
								border-style: solid;
								border-color: #f9f9f9 transparent transparent transparent;
							}

							&:hover .tooltiptext {
								visibility: visible;
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.mid {
			color: #333333;
			width: 100%;
			margin-top: 10px;
			margin-bottom: 10px;

			.description {
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 0.08px;
				line-height: 18px;
			}

			.plus {
				font-weight: 400;
				color: ${theme.palette.primary.main};
			}
		}

		.bottom {
			width: 100%;
			position: relative;

			.group {
				width: 100%;
				height: 100px;
				display: flex;
				flex-direction: row;
				align-items: flex-end;

				.left {
					.duration {
						color: #555555;
						.icon {
							height: 13px;
							left: 0;
							top: 2px;
							position: absolute;
						}

						.text {
							font-size: 14px;
							font-weight: 400;
							height: 18px;
							left: 21px;
							top: -1px;
							letter-spacing: 0;
							line-height: normal;
							position: absolute;
							width: max-content;
						}
					}

					.weekDays {
						color: #555555;

						.icon {
							height: 13px;
							left: 0;
							margin-top: 3px;
						}

						.text {
							font-size: 14px;
							font-weight: 400;
							height: 18px;

							letter-spacing: 0;
							line-height: normal;
							position: absolute;
						}
					}
				}
				.right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.price_label {
						color: #7f7f7f;
						font-size: 12px;
						font-weight: 500;

						letter-spacing: 0;
						line-height: normal;
					}

					.price_text {
						color: ${theme.palette.primary.main};
						font-size: 18px;
						font-weight: 700;

						line-height: normal;
						text-align: right;
					}
				}
			}

			${theme.breakpoints.down('sm')} {
				font-size: 12px;
			}
		}
	`}
`;
