import { useEffect, useState } from 'react';
import { pt } from 'react-date-range/dist/locale';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useHistory } from 'react-router-dom';
import { addDays, addMonths, format, isAfter, parse } from 'date-fns';
import TimerIcon from '@mui/icons-material/Timer';
import * as S from './styles';
import { Grid } from '@material-ui/core';
import { useGlobal } from '../../contexts/globalContext';
import Loader from '../Loader';
import ThemeButton from '../ThemeButton';
import EntertainmentRepository from '../../repositories/entertainmentRepository';
import ControllableCounter from './../ControllableCounter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function CheckAvailabilityModal({ open, onClose, activity }) {
	const { setLoading, user, showAlert } = useGlobal();
	const [openLoader, setOpenLoader] = useState(false);

	const [date, setDate] = useState(null);
	let history = useHistory();
	const [selectedActivity, setSelectedActivity] = useState({});
	const [selectedActivityType, setSelectedActivityType] = useState({});
	const [activityData, setActivityData] = useState({});
	const [isPointPrice, setIsPointPrice] = useState(true);

	const entertainmentRepo = new EntertainmentRepository();

	const [selectedDateFormatted, setSelectedDateFormatted] = useState(null);
	const [adultPrice, setAdultPrice] = useState(null);
	const [showFullTextDescription, setShowFullTextDescription] = useState(false);
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const showFullTextTitle = false;

	const LIMIT_CHARS_TITLE = 150;
	const LIMIT_CHARS_DESCRIPTION = 500;
	const isTitleLong =
		activity && selectedActivity && selectedActivity.name
			? selectedActivity.name.length > LIMIT_CHARS_TITLE
			: false;

	const displayedTitle =
		activity && selectedActivity && selectedActivity.name
			? showFullTextTitle
				? selectedActivity.name
				: selectedActivity.name.substring(0, LIMIT_CHARS_TITLE)
			: '';

	const isDescriptionLong =
		activity && selectedActivity && selectedActivity.description
			? selectedActivity.description.length > LIMIT_CHARS_DESCRIPTION
			: false;
	const displayedDescription =
		activity && selectedActivity && selectedActivity.description
			? showFullTextDescription
				? selectedActivity.description
				: selectedActivity.description.substring(0, LIMIT_CHARS_DESCRIPTION)
			: '';

	const toggleText = () => {
		setShowFullTextDescription((prev) => !prev);
	};

	const [passengerCount, setPassengerCount] = useState({
		Adulto: 1,
		Crianca: 0,
	});

	const updatePassengerCount = (type, value) => {
		setPassengerCount((prev) => ({
			...prev,
			[type]: Math.max(0, (prev[type] || 0) + value),
		}));
	};

	const handleCheckAvailability = async () => {
		const requestFormattedDate = format(date, 'yyyy-MM-dd');

		const passengerRate = [];
		let selectedActivityPassengerRate =
			selectedActivity.tourPassengerRate ||
			selectedActivity.ticketPassengerRate ||
			selectedActivity.transferPassengerRate;

		if (!selectedActivityPassengerRate) {
			showAlert(
				'error',
				'Infelizmente não encontramos uma disponibilidade para a data selecionada nesse momento, busque outras datas ou tente novamente mais tarde.'
			);
			console.error('Passenger rate não encontrado.');
			return;
		}
		selectedActivityPassengerRate.forEach((rate) => {
			const quantity = passengerCount[rate.name] || 0;

			for (let i = 0; i < quantity; i++) {
				passengerRate.push({
					endAge: rate.endAge,
					quantity: 1,
				});
			}
		});

		if (passengerRate.length === 0) {
			showAlert('error', 'Por favor, selecione pelo menos um tipo de passageiro.');
			console.error('Nenhum passageiro selecionado.');
			return;
		}

		const availabilityResponse = await entertainmentRepo.checkAvailability(
			selectedActivity.activityId,
			selectedActivity.refToken,
			passengerRate,
			requestFormattedDate
		);

		if (availabilityResponse) {
			sessionStorage.setItem(
				'@EntertainmentAvailabilitySelected',
				JSON.stringify(availabilityResponse[0])
			);

			history.push('/busca/entertainment-details');
		} else {
			showAlert(
				'error',
				'Infelizmente não encontramos uma disponibilidade para a data selecionada nesse momento, busque outras datas ou tente novamente mais tarde.'
			);
			console.error('Nenhum dado de disponibilidade retornado');
		}
	};

	const isValidDate = (dateString) => {
		const regex = /^\d{2}\/\d{2}\/\d{4}$/;
		return regex.test(dateString);
	};

	const availableDatesFormatted = Array.isArray(selectedActivity?.avaiableDates)
		? selectedActivity.avaiableDates
				.filter((date) => isValidDate(date))
				.map((date) => parse(date, 'dd/MM/yyyy', new Date()))
		: [];

	const isDateAvailable = (date) => {
		if (!(date instanceof Date) || isNaN(date.getTime())) {
			return false;
		}

		const formattedDate = format(date, 'dd/MM/yyyy');

		return availableDatesFormatted.some((availableDate) => {
			return format(availableDate, 'dd/MM/yyyy') === formattedDate;
		});
	};

	useEffect(() => {
		if (activity && activity.passengerRate) {
			const initialCounts = activity.passengerRate.reduce((acc, rate) => {
				acc[rate.name] = rate.name === 'Adulto' ? 1 : 0;
				return acc;
			}, {});
			setPassengerCount(initialCounts);
		}
	}, [activity]);

	useEffect(() => {
		if (activity?.activity) {
			setSelectedActivity(activity.activity);
			setSelectedActivityType(
				activity.activity?.tour?.tourType ||
					activity.activity?.ticket?.ticketType ||
					activity.activity?.transfer?.transferType
			);
		}
	}, [activity]);

	useEffect(() => {
		if (activityData && Object.keys(activityData).length > 0) {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activityData]);

	useEffect(() => {
		let price = null;
		if (user?.campaign?.disablePointActivity) {
			setIsPointPrice(false);
			if (
				activity?.activity?.ticketPassengerRate ||
				activity?.activity?.tourPassengerRate ||
				activity?.activity?.transferPassengerRate
			) {
				price = activity.activity.price;
			}
		} else {
			setIsPointPrice(true);
			if (
				activity?.activity?.ticketPassengerRate ||
				activity?.activity?.tourPassengerRate ||
				activity?.activity?.transferPassengerRate
			) {
				price = activity.activity.pointsPrice;
			}
		}

		if (price !== null) {
			setAdultPrice(price);
		}
	}, [activity]);

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<S.Modal>
			<Loader openLoader={openLoader} isGeneral={true} isBooking={false} />
			{selectedActivity ? (
				<Dialog
					open={open}
					maxWidth='md'
					fullWidth={true}
					onClose={() => onClose(false)}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogContent dividers>
						<S.Wrapper>
							<Grid container direction='row' spacing={2}>
								<Grid item xs={12} md={12} lg={12}>
									<div className='title'>
										<span>
											{displayedTitle}
											{isTitleLong && (
												<span className='plus'>{showFullTextTitle ? '' : '...'}</span>
											)}
										</span>
									</div>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<Grid
										container
										direction='row'
										sx={{
											justifyContent: 'space-between',
											alignItems: 'stretch',
										}}
									>
										<Grid item xs={12} md={6} lg={6}>
											<S.CardContent container>
												<Grid item className='container_img'>
													<img
														className='img'
														src={selectedActivity.pictureUrl}
														alt={selectedActivity.name}
													/>
												</Grid>
												<Grid item>
													<Grid container direction='row' className='container_details'>
														<S.ItemContent item className='top'>
															<Grid
																container
																direction='row'
																sx={{
																	justifyContent: 'space-between',
																	alignItems: 'flex-start',
																}}
															>
																<S.ItemContent item xs={6} md={6} lg={6}>
																	<span className='type'>
																		{(() => {
																			if (selectedActivity?.activityId?.startsWith('4')) {
																				return 'Transfer';
																			} else if (selectedActivity?.activityId?.startsWith('5')) {
																				return 'Ingresso';
																			} else if (selectedActivity?.activityId?.startsWith('6')) {
																				return 'Passeio';
																			}
																		})()}
																	</span>
																</S.ItemContent>
																<S.ItemContent
																	item
																	xs={6}
																	md={6}
																	lg={6}
																	className='location-container'
																>
																	<span className='location'>{selectedActivity.location}</span>
																</S.ItemContent>
																<S.ItemContent
																	item
																	xs={12}
																	md={12}
																	lg={12}
																	className='cancelation-container'
																>
																	{selectedActivity.daysBeforeCancellationPolicy > 0 && (
																		<div className='separator'>
																			<div className='cancelation'>
																				Cancelamento grátis
																				<div className='tooltiptext'>
																					<h3>
																						Cancele sem multa até{' '}
																						{selectedActivity.daysBeforeCancellationPolicy} dias antes
																						da atividade.
																					</h3>
																					<p>
																						Em menos tempo ou em caso de não comparecimento no dia da
																						atividade não será oferecido reembolso
																					</p>
																				</div>
																			</div>
																		</div>
																	)}
																</S.ItemContent>
															</Grid>
														</S.ItemContent>
														<S.ItemContent item className='mid'>
															<span className='description'>
																{displayedDescription}
																{isDescriptionLong && (
																	<span className='plus' onClick={toggleText}>
																		{showFullTextDescription ? ' ver menos' : '... ver mais'}
																	</span>
																)}
															</span>
														</S.ItemContent>

														<S.ItemContent item className='bottom'>
															<div className='group'>
																<Grid
																	container
																	direction='row'
																	sx={{
																		justifyContent: 'space-between',
																		alignItems: 'flex-end',
																	}}
																>
																	<S.ItemContent item className='left' xs={8} md={8} lg={8}>
																		<div className='weekDays'>
																			<TimerIcon className='icon' alt='weekDays-icon' />
																			<span className='text'>{selectedActivity.weekDays}</span>
																			<span className='text' hidden>
																				{selectedActivity.weekDaysList}
																			</span>
																		</div>
																		<div className='duration'>
																			{selectedActivity.duration > 0 ? (
																				<CalendarMonthIcon className='icon' alt='calendar-icon' />
																			) : (
																				''
																			)}
																			<span className='text'>
																				{selectedActivity.duration > 0
																					? 'Duração: ' + selectedActivity.duration + 'h'
																					: ''}
																			</span>
																		</div>
																	</S.ItemContent>
																	<S.ItemContent item className='right' xs={4} md={4} lg={4}>
																		<span className='price_label'>A partir de</span>
																		{isPointPrice ? (
																			<span className='price_text'>
																				{adultPrice} {shortenCoinAlias}
																			</span>
																		) : (
																			<span className='price_text'>
																				{shortenCoinAlias}{' '}
																				{adultPrice?.toLocaleString('pt-br', {
																					minimumFractionDigits: 2,
																					maximumFractionDigits: 2,
																				})}
																			</span>
																		)}
																	</S.ItemContent>
																</Grid>
															</div>
														</S.ItemContent>
													</Grid>
												</Grid>
											</S.CardContent>
										</Grid>
										<Grid item xs={12} md={6} lg={6} className='calendar-container'>
											<Grid
												container
												direction='column'
												sx={{
													justifyContent: 'space-around',
													alignItems: 'stretch',
												}}
												className='calendar'
											>
												<S.Price item>
													<div className='top'>
														<span className='label1'>A partir de:</span>
														<div>
															{isPointPrice ? (
																<span className='price'>
																	{adultPrice} {shortenCoinAlias}
																</span>
															) : (
																<span className='price'>
																	{shortenCoinAlias}{' '}
																	{adultPrice?.toLocaleString('pt-br', {
																		minimumFractionDigits: 2,
																		maximumFractionDigits: 2,
																	})}
																</span>
															)}
															<span className='label2'> / por serviço</span>
														</div>
													</div>

													{selectedActivityType ? (
														<div className='mid'>
															<div className='separator'>
																<div className='cancelation tooltip'>
																	<span className='label3'>ver condições</span>
																	<div className='tooltiptext'>
																		<h3>Preço válido para:</h3>
																		<p>
																			{selectedActivityType.type}{' '}
																			{selectedActivityType.maxNumberPassenger > 0
																				? '- Até ' +
																				  selectedActivityType.maxNumberPassenger +
																				  ' paxs'
																				: ''}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													) : (
														<></>
													)}
												</S.Price>
												<S.Quantity item>
													<div className='quantity-top'>
														<span className='label1'>
															Escolha a quantidade de pessoas&nbsp;
														</span>
														<span className='label2'>(Obrigatório)</span>
													</div>
													<div className='bottom'>
														{activity.passengerRate?.map((rate, index) => (
															<div key={index} className='passenger-item'>
																<span className='label'>{rate.name}</span>
																<ControllableCounter
																	count={passengerCount[rate.name] || 0}
																	onIncrement={() => updatePassengerCount(rate.name, 1)}
																	onDecrement={() => updatePassengerCount(rate.name, -1)}
																	isAdult={rate.name === 'Adulto'}
																/>
															</div>
														))}
													</div>
												</S.Quantity>
												<S.ItemContent item>
													<S.DateCalendar
														onChange={(item) => {
															setDate(item);
															setSelectedDateFormatted(item);
														}}
														locale={pt}
														date={date}
														minDate={addDays(new Date(), 0)}
														maxDate={addMonths(new Date(), 12)}
														dayContentRenderer={(date) => {
															const isAvailable =
																isDateAvailable(date) && isAfter(date, new Date());

															if (isAvailable) {
																return (
																	<div
																		style={{
																			opacity: 1,
																			pointerEvents: 'auto',
																			color: '#333',
																			padding: '5px',
																		}}
																	>
																		{date.getDate()}{' '}
																	</div>
																);
															}

															return (
																<div
																	style={{
																		opacity: 0.5,
																		pointerEvents: 'none',
																		color: '#d3d3d3',
																		padding: '5px',
																	}}
																>
																	{date.getDate()}{' '}
																</div>
															);
														}}
														tileDisabled={({ date }) =>
															!isDateAvailable(date) || !isAfter(date, new Date())
														}
													/>
												</S.ItemContent>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</S.Wrapper>
					</DialogContent>
					<DialogActions>
						<ThemeButton
							onClick={() => {
								onClose(false);
							}}
							color='primary'
							className='close'
						>
							Fechar
						</ThemeButton>

						<ThemeButton
							onClick={() => {
								setOpenLoader(true);
								if (!date) {
									setOpenLoader(false);
									showAlert(
										'error',
										'Por favor, selecione uma data antes de verificar a disponibilidade.'
									);
									return;
								}

								try {
									const selectedDate = new Date(selectedDateFormatted);
									if (isNaN(selectedDate.getTime())) {
										throw new Error('Data inválida selecionada');
									}

									const isAvailable = isDateAvailable(date);

									if (isAvailable) {
										handleCheckAvailability();
										// availabilityBoxRef.current.scrollIntoView({ behavior: 'smooth' });
									} else {
										setOpenLoader(false);
										showAlert(
											'error',
											'Dia indisponível, por favor selecione um dos dias destacados no calendário.'
										);
									}
								} catch (error) {
									setOpenLoader(false);
									console.error('Erro ao formatar data:', error.message);
									showAlert(
										'error',
										'Data inválida, por favor selecione uma data válida.'
									);
								}
							}}
							className='choose-entertainment'
						>
							Verificar disponibilidade
						</ThemeButton>
					</DialogActions>
				</Dialog>
			) : null}
		</S.Modal>
	);
}

export default CheckAvailabilityModal;
