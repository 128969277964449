/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import AppendHead from "react-append-head";

import {
  // Checkbox,
  Container,
  // FormControlLabel,
  Grid,
  MenuItem,
  Portal,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import FormInput from "../FormInput";

import * as S from "./styles";
import FlightSummaryCard from "./FlightSummaryCard";
import getCompanyLogos from "../../mock/companyLogos";
import FlightSideSumary from "./FlightSideSumary";
import CarSummaryCard from "./CarSummaryCard";
import HotelSummaryCard from "./HotelSummaryCard";
import CarSideSummary from "./CarSideSummary";
import HotelSideSummary from "./HotelSideSummary";
import BookingsRepository from "../../repositories/bookingsRepository";
import cardFlag from "../../utils/getFlagCard";
import { useGlobal } from "../../contexts/globalContext";
import { ufs } from "../../utils/ufList";
import createCard64 from "../../utils/createCard64";
import UserRepository from "../../repositories/userRepository";
import ThemeButton from "../ThemeButton";
import CardFlags from "../CardFlags";
import Auth2faModal from "../Auth2FaModal";
import Loader from "../Loader";
import ExperienceSideSummary from "./ExperienceSideSummary";
import EntertainmentSideSummary from "./EntertainmentSideSummary";
import HotelSummaryCardOld from "./HotelSummaryCardOld";
import HotelSideSummaryOld from "./HotelSideSummaryOld";
import { getCep } from "../../services/via-cep.datasource";
import { getClientIpAddress } from "../../services/get-client-ip";
import { addFeeByDestinoFerias } from "../../utils/addFeeByDestinoFerias";
import { discountTypeEnum } from "../../utils/enum";
import Alert from "@material-ui/lab/Alert";
import { Link } from "@mui/material";
import { validEmail } from "../../utils/validator";
import SimpleModal from "../SimpleModal";
import getByTag from "../../repositories/termRepository";
import { getSessionStorage } from "../../utils/getSessionStorage";

import { MAX_INSTALLMENTS } from "../../constants/constants";

import SimpleBusModal from "../SimpleBusModal";
import BusSideSummary from "./BusSideSummary";
import BusSummaryCard from "./BusSummaryCard";
import BusRepository from "./../../repositories/busRepository";
import EntertainmentRepository from "../../repositories/entertainmentRepository";

const SummaryContent = ({
  searchState,
  buscaAtual,
  hotelSearchResultsInfos,
}) => {
  const CodeAntiFraud = crypto.randomUUID();
  const ORG_ID = "1snn5n9w";
  const URL = `https://h.onlinemetrix.net/fp/tags.js?org_id=${ORG_ID}&session_id=adiq_br${CodeAntiFraud}`;

  const { showAlert, setLoading, user, signed, login } = useGlobal();
  let history = useHistory();

  const bookingsRepository = new BookingsRepository();
  const userRepository = new UserRepository();
  const busRepository = new BusRepository();
  const entertainmentRepository = new EntertainmentRepository();

  const [installmentsNumber, setInstallmentsNumber] = useState([]);
  const [dataSelected, setDataSelected] = useState();
  const [goingFlight, setGoingFlight] = useState();
  const [returnFlight, setReturnFlight] = useState();
  const [goingRoute, setGoingRoute] = useState();
  const [returnRoute, setReturnRoute] = useState();
  const [selectedGoingSeats, setSelectedGoingSeats] = useState([]);
  const [selectedReturnSeats, setSelectedReturnSeats] = useState([]);
  const [result, setResult] = useState({});
  const [seatMapIda, setSeatMapIda] = useState([]);
  const [seatMapVolta, setSeatMapVolta] = useState([]);
  const [loadingSeats, setLoadingSeats] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [state, setState] = useState({});
  const [quartos, setQuartos] = useState([]);
  const [send, setSend] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [userCardDataSaved, setUserCardDataSaved] = useState("");
  const [useSavedCard, setUseSavedCard] = useState(false);
  const [openModal2fa, setOpenModal2fa] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [bookingSuccessCode, setBookingSuccessCode] = useState({});
  const [discountCoupon, setDiscountCoupon] = useState("");
  const [discountPoints, setDiscountPoints] = useState(0);
  const [campaignDiscountPoints, setCampaignDiscountPoints] = useState(0);
  const [campaignDiscountCash, setCampaignDiscountCash] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountCash, setDiscountCash] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [fees, setFees] = useState(0);
  const [minimumPointsToBuy, setMinimumPointsToBuy] = useState(0);
  const [cashPrice, setCashPrice] = useState(0);
  const [cashTotalValue, setCashTotalValue] = useState(0);
  const [pointsPrice, setPointsPrice] = useState(0);
  const [pointsTotalValue, setPointsTotalValue] = useState(0);
  const [paymentInMoney, setPaymentInMoney] = useState(0);
  const [paymentInPoints, setPaymentInPoints] = useState(0);
  const [ipAddress, setIpAddress] = useState("");
  const [coinAlias, setCoinAlias] = useState("Pontos");
  const [activityCategory, setActivityCategory] = useState();
  const [isStartingAirport, setIsStartingAirport] = useState(false);
  const [isDestinationAirport, setIsDestinationAirport] = useState(false);
  const [transferData, setTransferData] = useState({
    startingAirport: {
      name: "",
      reservationCode: "",
      flightNumber: "",
      arrivalTime: "",
    },
    destinationAirport: {
      name: "",
      reservationCode: "",
      flightNumber: "",
      arrivalTime: "",
    },
    pickupLocation: "",
    dropOffLocation: "",
  });

  // const [saveCardData, setSaveCardData] = useState(true);
  const userPoints = signed ? user.pointsBalance : 0;
  // const userPoints = 10000;
  const [
    openServiceTermsAndPrivacyPolicyModal,
    setOpenServiceTermsAndPrivacyPolicyModal,
  ] = useState(false);

  const [
    openServiceTermsAndPrivacyPolicyBusModal,
    setOpenServiceTermsAndPrivacyPolicyBusModal,
  ] = useState(false);

  const [useTerms, setUseTerms] = useState("");
  const [isGeneralLoading, setIsGeneralLoading] = useState(false);

  const [userDataAddress, setUserDataAddress] = useState({
    zipCode: "",
    address: "",
    number: "",
    city: "",
    state: "",
    complement: "",
    documentNumber: "",
    email: "",
    mobilePhoneNumber: "",
    ipAddress: "",
  });

  const conversionRate = signed
    ? user.catalog.catalogCurrencyConfig.conversionRate
    : 1;

  const sending = () => setSend(true);

  const getClientIp = async () => {
    const data = await getClientIpAddress();
    setIpAddress(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClientIp();
  }, []);

  useEffect(async () => {
    const useTermsResponse = await getByTag("USE_TERMS_PRIVACY_POLICY");
    setUseTerms(useTermsResponse?.data);
  }, []);

  useEffect(() => {
    if (buscaAtual === "Aéreo")
      setDataSelected(getSessionStorage("@FlightSelected"));
    if (buscaAtual === "Carro")
      setDataSelected(getSessionStorage("@CarSelected"));
    if (buscaAtual === "Experiencias")
      setDataSelected(getSessionStorage("@ExperienceSelected"));
    if (buscaAtual === "Hotel")
      setDataSelected(getSessionStorage("@roomSelected"));
    if (buscaAtual === "Rodoviário")
      setDataSelected(getSessionStorage("@BusSelected"));
    if (buscaAtual === "Entretenimento")
      setDataSelected(getSessionStorage("@EntertainmentSelected"));
  }, [buscaAtual]);

  useEffect(() => {
    if (buscaAtual === "Aéreo") {
      setGoingFlight(
        dataSelected?.recommendation.journeys[0].segments.find(
          (s) => s.segmentid.toString() === dataSelected?.going.split("-")[1]
        )
      );
      if (dataSelected?.return)
        setReturnFlight(
          dataSelected?.recommendation.journeys[1].segments.find(
            (s) => s.segmentid.toString() === dataSelected?.return.split("-")[1]
          )
        );
    }
  }, [buscaAtual, dataSelected]);

  useEffect(() => {
    if (buscaAtual === "Rodoviário") {
      setGoingRoute(dataSelected?.selectedDepart);
      if (dataSelected?.selectedReturn)
        setReturnRoute(dataSelected?.selectedReturn);
    }
  }, [buscaAtual, dataSelected]);

  const createPassengerByPersonas = (adults, childs, babies) => {
    let pass = [];

    if (adults > 0) {
      for (var a = 0; a < adults; a++) {
        pass.push({ tipo: "Adulto", index: pass.length });
      }
    }
    if (childs > 0) {
      for (var c = 0; c < childs; c++) {
        pass.push({ tipo: "Criança", index: pass.length });
      }
    }
    if (babies > 0) {
      for (var b = 0; b < babies; b++) {
        pass.push({ tipo: "Bebê", index: pass.length });
      }
    }

    setPassengers(pass);

    setState({
      ...state,
      passengers: pass.map((index) => ({ name: "" })),
    });

    return pass;
  };

  const createGuestsByPersonas = (adults, childAges) => {
    let pass = [];

    if (adults > 0) {
      for (var a = 0; a < adults; a++) {
        pass.push({ tipo: "Adulto", label: "Adulto", index: pass.length });
      }
    }
    if (childAges?.length > 0) {
      childAges.map((child, index) =>
        pass.push({
          tipo: "Criança",
          label: `Criança (${child}) anos`,
          index: pass.length,
        })
      );
    }

    setPassengers(pass);

    setState({
      ...state,
      passengers: pass.map((index) => ({ name: "" })),
    });

    return pass;
  };

  useEffect(() => {
    buscaAtual === "Aéreo" &&
      createPassengerByPersonas(
        searchState?.adultos,
        searchState?.criancas,
        searchState?.bebes
      );

    buscaAtual === "Experiencias" &&
      createPassengerByPersonas(dataSelected?.adults, 0, 0);

    buscaAtual === "Carro" && createPassengerByPersonas(1, 0, 0);

    buscaAtual === "Hotel" &&
      createGuestsByPersonas(searchState?.qtAdult, searchState?.childAge);

    buscaAtual === "Rodoviário" &&
      createPassengerByPersonas(searchState?.passageiros, 0, 0);

    if (buscaAtual === "Entretenimento" && dataSelected?.passageiros) {
      const { adults, childs, babies } = mapPassengerData(
        dataSelected.passageiros
      );

      createPassengerByPersonas(adults, childs, babies);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, dataSelected]);

  const mapPassengerData = (passengerObj) => {
    let adults = 0;
    let childs = 0;
    let babies = 0;

    Object.keys(passengerObj).forEach((key) => {
      if (key.includes("Adulto")) {
        adults += passengerObj[key];
      } else if (key.includes("Criança")) {
        if (buscaAtual === "Entretenimento" && key.startsWith("Criança (0")) {
          babies += passengerObj[key];
        } else {
          childs += passengerObj[key];
        }
      } else if (key.includes("Bebê")) {
        babies += passengerObj[key];
      }
    });

    return { adults, childs, babies };
  };

  useEffect(() => {
    if (user.id) {
      userRepository.getUserDetails(user.id).then((snapshot) => {
        const res = snapshot.docs.map((i) => i.data());
        setUserCardDataSaved(res);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const getValuesByUser = (val) => {
    let value = signed
      ? val * user.catalog.catalogCurrencyConfig.conversionRate
      : val;

    if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

    return signed ? Math.ceil(Math.round(value)) : value;
  };

  const getValuesByUserWithoutFee = (val) => {
    let value = signed
      ? val * user.catalog.catalogCurrencyConfig.conversionRate
      : val;

    if (buscaAtual === "Hotel") {
      return signed ? Math.ceil(Math.round(value)) : value;
    } else {
      return signed
        ? Math.ceil(
            // Math.round(
            value
            // )
          )
        : value;
    }
  };

  useEffect(() => {
    if (buscaAtual === "Aéreo") {
      // let adt = dataSelected?.recommendation.fares.find(
      //   (x) => x.paxtype === 'ADT'
      // )?.fareamount;

      // let chd = dataSelected?.recommendation.fares.find(
      //   (x) => x.paxtype === 'CHD'
      // )?.fareamount;

      // let inf = dataSelected?.recommendation.fares.find(
      //   (x) => x.paxtype === 'INF'
      // )?.fareamount;

      let fee =
        dataSelected?.recommendation.totalfare.totaldu +
        dataSelected?.recommendation.totalfare.totaltaxes;

      // -!isNaN(adt) ? setAdultsTotal(getValuesByUser(adt)) : setAdultsTotal(0);
      // -!isNaN(chd) ? setChildsTotal(getValuesByUser(chd)) : setChildsTotal(0);
      // -!isNaN(inf) ? setBabiesTotal(getValuesByUser(inf)) : setBabiesTotal(0);
      !isNaN(fee) ? setFees(getValuesByUser(fee)) : setFees(0);

      const total = dataSelected?.recommendation.totalfare.total;
      setCashPrice(addFeeByDestinoFerias(total, buscaAtual));
    }

    if (buscaAtual === "Experiencias") {
      let adt = dataSelected?.experience.price;

      const total =
        adt * (dataSelected?.adults / dataSelected?.experience.qtParticipants);
      setCashPrice(addFeeByDestinoFerias(total, buscaAtual));
    }

    if (buscaAtual === "Carro") {
      let total = dataSelected?.totalAmount;
      // let chd = dataSelected?.experience.price;

      // -!isNaN(adt) ? setAdultsTotal(getValuesByUser(adt)) : setAdultsTotal(0);
      setCashPrice(addFeeByDestinoFerias(total, buscaAtual));
    }

    if (buscaAtual === "Hotel") {
      let total = 0;

      if (searchState)
        total =
          dataSelected?.rateSelected[
            dataSelected?.rateSelected.total.currencyCode === "BRL"
              ? "total"
              : "origTotal" || "total"
          ].amountAfterTax;

      // -!isNaN(adt) ? setAdultsTotal(getValuesByUser(adt)) : setAdultsTotal(0);
      setCashPrice(addFeeByDestinoFerias(total, buscaAtual));
    }

    if (buscaAtual === "Rodoviário") {
      let goingFare = 0;
      let returnFare = 0;
      let total = 0;

      if (searchState.idavolta === "true") {
        goingFare = dataSelected?.selectedDepart.pointsValue;
        returnFare = dataSelected?.selectedReturn?.pointsValue;
        total = goingFare + returnFare;
      } else {
        goingFare = dataSelected?.selectedDepart.pointsValue;
        total = goingFare;
      }

      setCashPrice(addFeeByDestinoFerias(total, buscaAtual));
    }

    if (buscaAtual === "Entretenimento") {
      let total = dataSelected?.data.totalPrice;

      // setCashPrice(addFeeByDestinoFerias(total, buscaAtual));
      setCashPrice(total);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscaAtual, dataSelected]);

  useEffect(() => {
    if (
      buscaAtual === "Aéreo" ||
      buscaAtual === "Experiencias" ||
      buscaAtual === "Carro" ||
      buscaAtual === "Hotel" ||
      buscaAtual === "Rodoviário" ||
      buscaAtual === "Entretenimento"
    ) {
      setPointsPrice(
        // Math.ceil(
        // Math.round(
        getValuesByUserWithoutFee(cashPrice)
        // )
        // )
      );
      if (user?.campaign?.acceptCashPayment) {
        setMinimumPointsToBuy(
          //regra sobrescrita pelo campo % pagamento em dinheiro criado no ADM
          // Math.ceil(
          // 	getValuesByUserWithoutFee(cashPrice) *
          // 		(user.catalog.minimumPercentagePoints / 100)
          // )

          Math.round(
            getValuesByUserWithoutFee(cashPrice) -
              discountCash -
              campaignDiscountCash -
              Math.ceil(
                getValuesByUserWithoutFee(
                  cashPrice - discountCash - campaignDiscountCash
                ) *
                  (user.campaign.cashPaymentPercentage / 100)
              )
          )
        );
      } else {
        setMinimumPointsToBuy(
          Math.ceil(getValuesByUserWithoutFee(cashPrice) * 1)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cashPrice,
    buscaAtual,
    conversionRate,
    discountCash,
    campaignDiscountCash,
  ]);

  const calculateCampaignDiscount = (
    cashPriceLessUserPoints,
    cashPrice,
    pointsPrice,
    pointsTotal,
    cashTotal
  ) => {
    if (
      !user?.campaign?.disablePointActivity &&
      user?.campaign?.coupon?.discountType === discountTypeEnum.POINTS
    ) {
      cashPriceLessUserPoints = Math.max(
        cashPrice -
          userPoints / conversionRate -
          user.campaign.coupon.value / conversionRate,
        0
      );
      pointsTotal =
        userPoints <= pointsPrice - user.campaign.coupon.value
          ? Math.max(userPoints - user.campaign.coupon.value, 0)
          : Math.max(pointsPrice - user.campaign.coupon.value, 0);
      cashTotal = parseFloat(
        userPoints <= pointsPrice - user.campaign.coupon.value
          ? cashPriceLessUserPoints
          : 0
      ).toFixed(2);
      setCampaignDiscountPoints(user.campaign.coupon.value);
      setCashTotalValue(cashTotal);
      setPointsTotalValue(pointsTotal);
      return;
    }

    if (user?.campaign?.coupon?.discountType === discountTypeEnum.PERCENTAGE) {
      let discountValueToBeAppliedTotal =
        cashPrice * (user.campaign.coupon.value / 100);
      let cashPriceWithDiscount = Math.max(
        cashPrice - discountValueToBeAppliedTotal,
        0
      );

      if (user?.campaign?.disablePointActivity) {
        cashPriceLessUserPoints = Math.max(cashPriceWithDiscount, 0);
        pointsTotal = 0;
        cashTotal = parseFloat(cashPriceLessUserPoints).toFixed(2);
      } else {
        cashPriceLessUserPoints = Math.max(
          cashPriceWithDiscount - userPoints / conversionRate,
          0
        );
        pointsTotal =
          userPoints <= pointsPrice
            ? Math.max(userPoints, 0)
            : Math.max(pointsPrice, 0);
        cashTotal = parseFloat(
          userPoints <= pointsPrice ? cashPriceLessUserPoints : 0
        ).toFixed(2);
      }

      setCampaignDiscountCash(
        Math.min(discountValueToBeAppliedTotal, cashPrice)
      );
      setDiscountPercentage(user.campaign.coupon.value);
      setCashTotalValue(cashTotal);
      setPointsTotalValue(pointsTotal);
      return;
    }
  };

  useEffect(() => {
    let cashPriceLessUserPoints = user?.campaign?.disablePointActivity
      ? cashPrice
      : Math.max(cashPrice - userPoints / conversionRate, 0);
    let pointsTotal = user?.campaign?.disablePointActivity
      ? 0
      : userPoints <= pointsPrice
      ? userPoints
      : pointsPrice;
    let cashTotal = user?.campaign?.disablePointActivity
      ? parseFloat(cashPriceLessUserPoints).toFixed(2)
      : parseFloat(
          userPoints <= pointsPrice ? cashPriceLessUserPoints : 0
        ).toFixed(2);

    if (buscaAtual === "Aéreo") {
      if (user?.campaign?.coupon?.air) {
        calculateCampaignDiscount(
          cashPriceLessUserPoints,
          cashPrice,
          pointsPrice,
          pointsTotal,
          cashTotal
        );
      } else {
        setCashTotalValue(cashTotal);
        setPointsTotalValue(pointsTotal);
      }
    }

    if (buscaAtual === "Experiencias") {
      if (user?.campaign?.coupon?.experience) {
        calculateCampaignDiscount(
          cashPriceLessUserPoints,
          cashPrice,
          pointsPrice,
          pointsTotal,
          cashTotal
        );
      } else {
        setCashTotalValue(cashTotal);
        setPointsTotalValue(pointsTotal);
      }
    }
    if (buscaAtual === "Carro") {
      if (user?.campaign?.coupon?.car) {
        calculateCampaignDiscount(
          cashPriceLessUserPoints,
          cashPrice,
          pointsPrice,
          pointsTotal,
          cashTotal
        );
      } else {
        setCashTotalValue(cashTotal);
        setPointsTotalValue(pointsTotal);
      }
    }
    if (buscaAtual === "Hotel") {
      if (user?.campaign?.coupon?.hotel) {
        calculateCampaignDiscount(
          cashPriceLessUserPoints,
          cashPrice,
          pointsPrice,
          pointsTotal,
          cashTotal
        );
      } else {
        setCashTotalValue(cashTotal);
        setPointsTotalValue(pointsTotal);
      }
    }
    if (buscaAtual === "Rodoviário") {
      if (user?.campaign?.coupon?.bus) {
        calculateCampaignDiscount(
          cashPriceLessUserPoints,
          cashPrice,
          pointsPrice,
          pointsTotal,
          cashTotal
        );
      } else {
        setCashTotalValue(cashTotal);
        setPointsTotalValue(pointsTotal);
      }
    }
    if (buscaAtual === "Entretenimento") {
      if (user?.campaign?.coupon?.entertainment) {
        calculateCampaignDiscount(
          cashPriceLessUserPoints,
          cashPrice,
          pointsPrice,
          pointsTotal,
          cashTotal
        );
      } else {
        setCashTotalValue(cashTotal);
        setPointsTotalValue(pointsTotal);
      }
    }
  }, [
    buscaAtual,
    pointsPrice,
    signed,
    cashPrice,
    user,
    userPoints,
    conversionRate,
  ]);

  const savedCard = (flag = "", lastFourDigits, vaultId) => {
    return (
      <S.CreditCard>
        <span>{flag}</span>
        <span>{` *${lastFourDigits}`}</span>
        <ThemeButton
          onClick={() => {
            setUseSavedCard(!useSavedCard);
            setSend(false);
          }}
          className="reprocessar"
        >
          {useSavedCard ? "Não usar cartão salvo" : "Usar cartão salvo"}
        </ThemeButton>
      </S.CreditCard>
    );
  };

  const castPointsToMoney = (pointsValue) => {
    if (pointsValue < pointsPrice) {
      setPaymentInMoney(
        parseFloat(
          cashPrice -
            pointsValue /
              // - discountPoints
              conversionRate
        ).toFixed(2)
      );
    }
  };

  const castMoneyToPoints = (moneyValue) => {
    if (moneyValue < cashPrice) {
      setPaymentInPoints(
        parseFloat(
          Math.round(Math.max(pointsPrice - moneyValue * conversionRate, 0))
        )
        // -
        //   discountPoints
      );
    }
  };

  // useEffect(() => {
  //   console.log('check - cashPrice', cashPrice);
  //   console.log('check - cashTotalValue', cashTotalValue);
  //   console.log('check - pointsPrice', pointsPrice);
  //   console.log('check - pointsTotalValue', pointsTotalValue);
  //   console.log('check - minimumPointsToBuy', minimumPointsToBuy);
  //   console.log('check - conversionRate', conversionRate);
  //   console.log('check - fees', fees);
  //   console.log('check - paymentInPoints', paymentInPoints);
  //   console.log('check - paymentInMoney', paymentInMoney);
  //   console.log('check - _____________________________');
  // }, [
  //   dataSelected,
  //   cashPrice,
  //   fees,
  //   cashTotalValue,
  //   conversionRate,
  //   minimumPointsToBuy,
  //   pointsPrice,
  //   pointsTotalValue,
  //   paymentInMoney,
  //   paymentInPoints,
  //   user,
  // ]);

  // useEffect(() => console.log('dataSelected', dataSelected), [dataSelected]);

  const onChangeField = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onChangeFieldCobranca = (e) => {
    setUserDataAddress({ ...userDataAddress, [e.target.name]: e.target.value });
  };

  const onChangeRoomGuests = (obj) => {
    let quarto = quartos.find((q) => q.quarto === obj.quarto);

    if (obj.prop === "name" || obj.prop === "lastName") {
      const normalizedValue =
        obj.value || (obj.target && obj.target.value) || "";

      const processedValue = normalizedValue
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z ]/g, "")
        .toUpperCase();
      obj.value = processedValue;
      if (obj.target && obj.target.value !== undefined) {
        obj.target.value = processedValue;
      }
    }

    if (quarto) {
      let hospede = quarto.hospedes.find((h) => h.hospede === obj.hospede);
      if (hospede) {
        hospede = {
          ...hospede,
          [obj.prop]: obj.value,
          main: obj.quarto === 0 && obj.hospede === 0 ? true : false,
        };
      } else {
        hospede = {
          hospede: obj.hospede,
          [obj.prop]: obj.value,
          main: obj.quarto === 0 && obj.hospede === 0 ? true : false,
        };
      }
      quarto = {
        quarto: obj.quarto,
        hospedes: [
          ...quarto.hospedes.filter((h) => h.hospede !== obj.hospede),
          hospede,
        ],
      };
    } else {
      quarto = {
        quarto: obj.quarto,
        hospedes: [
          {
            hospede: obj.hospede,
            [obj.prop]: obj.value,
            main: obj.quarto === 0 && obj.hospede === 0 ? true : false,
          },
        ],
      };
    }

    setQuartos([...quartos.filter((q) => q.quarto !== quarto.quarto), quarto]);
  };

  const onChangePassengers = (type, index, e) => {
    if (e.target.name === "name" || e.target.name === "lastName") {
      e.target.value = e.target.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();
    }

    setState({
      ...state,
      passengers: {
        ...state.passengers,
        [index]: {
          ...state.passengers[index],
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const mountRoomStaysBooking = () => {
    let roomStays = getSessionStorage("@hotelSelected").roomStays;

    roomStays.roomTypes = [{ ...dataSelected.room }];

    roomStays.roomTypes[0].roomRates = [{ ...dataSelected.rateSelected }];

    return roomStays;
  };

  useEffect(() => {
    if (dataSelected?.availabilityData?.activityId.startsWith("4")) {
      setActivityCategory("transfer");
    } else if (dataSelected?.availabilityData?.activityId.startsWith("5")) {
      setActivityCategory("ticket");
    } else if (dataSelected?.availabilityData?.activityId.startsWith("6")) {
      setActivityCategory("tour");
    }
  }, [dataSelected]);

  const mountRequest = () => {
    let segments = [];
    if (buscaAtual === "Aéreo") {
      if (returnFlight) {
        segments = [goingFlight, returnFlight];
      } else {
        segments = [goingFlight];
      }
    }

    if (buscaAtual === "Rodoviário") {
      if (returnRoute?.key) {
        segments = [goingRoute, returnRoute];
      } else {
        segments = [goingRoute];
      }
    }
    let type = "";

    let data = {};

    if (buscaAtual === "Aéreo") {
      type = "flight";
      data = {
        flight: {
          qtAdult: searchState?.adultos,
          qtChild: searchState?.criancas,
          qtBaby: searchState?.bebes,
          passengers: passengers.map((pass) => state.passengers[pass.index]),
          segments,
          cashPrice: parseFloat(cashPrice),
          pointsPrice: parseFloat(pointsPrice),
          pointsTotalValue:
            parseFloat(paymentInPoints) + parseFloat(discountPoints),
          cashTotalValue: parseFloat(paymentInMoney + discountCash),
        },
      };
    }

    if (buscaAtual === "Experiencias") {
      type = "experience";
      data = {
        experience: {
          qtAdult: dataSelected?.adults,
          qtChild: dataSelected?.childs,
          experienceId: dataSelected?.experience.id,
          experienceDateId: dataSelected?.experienceDate.id,
          refToken: dataSelected?.experience.refToken,
          passengers: passengers.map((pass) => state.passengers[pass.index]),
          cashPrice: parseFloat(cashPrice),
          pointsPrice: parseFloat(pointsPrice),
          pointsTotalValue:
            parseFloat(paymentInPoints) + parseFloat(discountPoints),
          cashTotalValue: parseFloat(paymentInMoney + discountCash),
          selectedDateFromContinuousExperience:
            dataSelected?.experienceDate?.date,
        },
      };
    }

    if (buscaAtual === "Hotel") {
      let guests = [];

      quartos.map((guestsRoom) => {
        let room = [];
        guestsRoom.hospedes.map((hospede) => {
          delete hospede.hospede;
          room.push({ ...hospede });
        });

        guests.push({ guestsRoom: [...room] });
      });

      type = "hotel";
      data = {
        hotelV2: {
          qtAdult: searchState?.qtAdult,
          qtChild: searchState?.qtChild,
          qtBaby: 0,
          echoToken: hotelSearchResultsInfos?.echoToken,
          addressCode: hotelSearchResultsInfos?.addressCode,
          qtRooms: searchState?.qtRooms,
          roomStays: mountRoomStaysBooking(),
          guests,
          cashPrice: parseFloat(cashPrice),
          pointsPrice: parseFloat(pointsPrice),
          pointsTotalValue: parseFloat(paymentInPoints),
          cashTotalValue: parseFloat(paymentInMoney),
        },
      };
    }

    if (buscaAtual === "Carro") {
      type = "car";
      data = {
        car: {
          car: dataSelected,
          passengers: passengers.map((pass) => state.passengers[pass.index]),
          cashPrice: parseFloat(cashPrice),
          pointsPrice: parseFloat(pointsPrice),
          pointsTotalValue:
            parseFloat(paymentInPoints) + parseFloat(discountPoints),
          cashTotalValue: parseFloat(paymentInMoney + discountCash),
        },
      };
    }

    if (buscaAtual === "Rodoviário") {
      type = "bus";

      const mappedPassengers = passengers.map((pass) => {
        const passengerData = state.passengers[pass.index];
        const birthdateParts = passengerData.birthdate.split("/");
        const birthdate = `${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`;
        return {
          firstName: passengerData.name,
          lastName: passengerData.lastName,
          birth: birthdate,
          document: {
            number: formatNumberString(passengerData.documentId),
            type: mapDocumentType(passengerData.documentType),
          },
          age: calculateAge(birthdate),
          gender: mapGender(passengerData.gender),
          isMain: pass.index === 0,
        };
      });

      const customer = {
        ...userDataAddress,
        state: userDataAddress.state.substring(
          0,
          userDataAddress.state.length - 5
        ),
        ipAddress: ipAddress,
      };

      data = {
        coupon: "",

        cashPrice: parseFloat(cashPrice),
        pointsPrice: parseFloat(pointsPrice),
        pointsTotalValue: parseFloat(
          parseFloat(paymentInPoints) + discountPoints
        ),
        cashTotalValue: parseFloat(parseFloat(paymentInMoney) + discountCash),

        services: [
          {
            type: type,
            data: {
              id: result?.booking?.id,
              date: result?.booking?.date,
              occupancy: result?.booking?.bookingBuses[0].names.length,
              passengers: mappedPassengers,
              goingBus: result?.booking?.bookingBuses[0].busList[0].key,
              goingBusRefToken:
                result?.booking?.bookingBuses[0].busList[0].refToken,
              goingSeats: selectedGoingSeats.map((seat) => ({
                seat: seat.toString(),
              })),
              returnBus: returnRoute?.key
                ? result?.booking?.bookingBuses[0].busList[1]?.key
                : null,
              returnBusRefToken: returnRoute?.key
                ? result?.booking?.bookingBuses[0].busList[1]?.refToken
                : null,
              returnSeats: returnRoute?.key
                ? selectedReturnSeats
                  ? selectedReturnSeats.map((seat) => ({
                      seat: seat.toString(),
                    }))
                  : []
                : null,
            },
          },
        ],
        payment: {
          customer: customer,

          paymentCode: CodeAntiFraud,
          cardInfo:
            state.cardNumber &&
            createCard64(
              state.cardHolder,
              state.cardNumber,
              state.cardExpiration,
              state.cardCCV
            ),
          installments: state.installments,
          saveCreditCard: false,
        },
      };

      return { data, type };
    }

    if (buscaAtual === "Entretenimento") {
      type = "entertainment";

      const mappedPassengers = passengers.map((pass) => {
        const passengerData = state.passengers[pass.index];
        const birthdateParts = passengerData.birthdate.split("/");
        const birthdate = `${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`;
        return {
          firstName: passengerData.name,
          lastName: passengerData.lastName,
          birth: birthdate,
          document: {
            number: formatNumberString(passengerData.documentId),
            type: mapDocumentType(passengerData.documentType),
          },
          gender: mapGender(passengerData.gender),
          phone: passengerData.phone,
          email: passengerData.email,
          isMain: pass.index === 0,
        };
      });

      const customer = {
        ...userDataAddress,
        state: userDataAddress.state.substring(
          0,
          userDataAddress.state.length - 5
        ),
        ipAddress: ipAddress,
      };

      data = {
        coupon: "",
        cashPrice: parseFloat(cashPrice),
        pointsPrice: parseFloat(pointsPrice),
        pointsTotalValue: parseFloat(
          parseFloat(paymentInPoints) + discountPoints
        ),
        cashTotalValue: parseFloat(parseFloat(paymentInMoney) + discountCash),

        services: [
          {
            type: type,
            data: {
              serviceId: dataSelected.availabilityData.serviceId,
              refToken: dataSelected.availabilityData.refToken,
              refTokenAdditionalInfo:
                dataSelected.availabilityData.refTokenAdditionalInfo,
              startingTime: dataSelected.availabilityData.startingTimes[0]
                ? dataSelected.availabilityData.startingTimes[0]
                : null,
              passengers: mappedPassengers,
            },
          },
        ],
        payment: {
          customer: customer,
          paymentCode: CodeAntiFraud,
          cardInfo:
            state.cardNumber &&
            createCard64(
              state.cardHolder,
              state.cardNumber,
              state.cardExpiration,
              state.cardCCV
            ),
          installments: state.installments,
          saveCreditCard: false,
        },
      };

      return { data, type };
    }

    const customer = {
      ...userDataAddress,
      state: userDataAddress.state.substring(
        0,
        userDataAddress.state.length - 5
      ),
      ipAddress: ipAddress,
    };

    if (paymentInMoney > 0) {
      data[type] = Object.assign(
        useSavedCard && userCardDataSaved.length > 0
          ? {
              paymentData: {
                customer,
                paymentCode: CodeAntiFraud,
                vaultId: userCardDataSaved[0].vaultId,
              },
            }
          : {
              paymentData: {
                customer,
                paymentCode: CodeAntiFraud,
                cardInfo:
                  state.cardNumber &&
                  createCard64(
                    state.cardHolder,
                    state.cardNumber,
                    state.cardExpiration,
                    state.cardCCV
                  ),
                installments: state.installments,
                // saveCreditCard: saveCardData,
                saveCreditCard: false,
              },
            },
        data[type]
      );
    }

    return { data, type };
  };

  useEffect(() => {
    if (buscaAtual === "Rodoviário" && dataSelected) {
      let isMounted = true;
      setLoadingSeats(true);

      const handleResponse = (response) => {
        if (isMounted) {
          setResult(response.data);

          const bookingBuses = response.data.booking.bookingBuses[0];
          if (bookingBuses.busList[0].segments.length > 0) {
            setSeatMapIda(bookingBuses.busList[0].segments[0].seatMap);
          }
          if (bookingBuses.busList[1].segments.length > 0) {
            setSeatMapVolta(bookingBuses.busList[1].segments[0].seatMap);
          }
          setPolicies(bookingBuses.policies);
          setLoadingSeats(false);
        }
      };

      const handleSubmit = async () => {
        const postData = {
          goingBus: dataSelected?.selectedDepart?.key,
          goingBusRefToken: dataSelected?.selectedDepart?.refToken || "", // Adicione o token ou deixe vazio
          returnBus:
            dataSelected?.idavolta === false
              ? dataSelected?.selectedDepart?.key
              : dataSelected?.selectedReturn?.key,
          returnBusRefToken:
            dataSelected?.idavolta === false
              ? dataSelected?.selectedDepart?.refToken || ""
              : dataSelected?.selectedReturn?.refToken || "", // Adicione o token ou deixe vazio
          occupancy: dataSelected?.passageiros || "1",
        };

        try {
          const response = await busRepository.checkBusRate(postData);
          handleResponse(response);

          if (response.data?.filter?.goingBusRefToken) {
            dataSelected.selectedDepart.refToken =
              response.data.filter.goingBusRefToken;
          }
          if (response.data?.filter?.returnBusRefToken) {
            dataSelected.selectedReturn.refToken =
              response.data.filter.returnBusRefToken;
          }
        } catch (error) {
          if (isMounted) {
            setError(
              error.response ? error.response.data : "An error occurred"
            );
          }
        }
      };

      handleSubmit();

      return () => {
        isMounted = false;
      };
    }
  }, [dataSelected]);

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const formatNumberString = (numberString) => {
    return numberString.replace(/\D/g, "");
  };

  const mapGender = (gender) => {
    switch (gender) {
      case "M":
        return "MALE";
      case "F":
        return "FEMALE";
      case "O":
        return "OTHER";
      default:
        return "";
    }
  };

  const mapDocumentType = (type) => {
    switch (type) {
      case "CNH":
        return "DRIVER_LICENSE";
      default:
        return type;
    }
  };

  const validateCoupon = async (coupon) => {
    if (discountPoints || discountCash) {
      setCouponError(
        "Já existe um cupom aplicado, remova-o para adicionar um novo!"
      );

      setIsGeneralLoading(false);
      setLoading(false);
      setOpenLoader(false);
    } else {
      let { data } = mountRequest();
      bookingsRepository
        .validateDiscount({ ...data, coupon })
        .then((res) => {
          if (
            res.data.couponCode?.coupon?.discountType ===
            discountTypeEnum.PERCENTAGE
          ) {
            setDiscountPercentage(
              discountPercentage + res.data.couponCode?.coupon?.value
            );
          }

          setPaymentInPoints(paymentInPoints - res.data.discountPoints);
          setPaymentInMoney(
            Math.round(paymentInMoney - res.data.discountCash).toFixed(2)
          );
          setDiscountPoints(res.data.discountPoints);
          setDiscountCash(res.data.discountCash);
          setIsGeneralLoading(false);
          setLoading(false);
          setOpenLoader(false);
        })
        .catch((err) => {
          setCouponError(err?.response?.data?.error);
          setIsGeneralLoading(false);
          setLoading(false);
          setOpenLoader(false);
        });
    }
  };

  const removeCoupon = () => {
    setPaymentInPoints(
      parseFloat(paymentInPoints) + parseFloat(discountPoints)
    );
    setPaymentInMoney(parseFloat(paymentInMoney) + parseFloat(discountCash));
    setDiscountCoupon("");
    setDiscountPoints(0);
    setDiscountCash(0);
    setCouponError("");
  };

  const onSubmit = async () => {
    const hasTheSameNameAndLastName =
      verifyIfAnyGuestOrPassangerHaveTheSameNameAndLastName();
    const isFromHub =
      buscaAtual === "Rodoviário" || buscaAtual === "Entretenimento";

    setLoading(true);
    setOpenLoader(true);
    await setTimeout(function () {}, 4000);

    if (document.getElementsByClassName("MuiFormHelperText-root").length > 0) {
      setLoading(false);
      setOpenLoader(false);

      return showAlert("error", "Preencha todos os campos");
    }
    if (hasTheSameNameAndLastName) {
      setLoading(false);
      setOpenLoader(false);

      return showAlert(
        "error",
        `${
          buscaAtual === "Hotel" ? "Hóspedes" : "Passageiros"
        } com o mesmo nome e sobrenome não são permitidos!`
      );
    } else if (!isFromHub) {
      const { data, type } = mountRequest();
      await bookingsRepository
        .postBooking({
          ...data,
          coupon: discountCoupon,
        })
        .then((res) => {
          setError("");
          setLoading(false);
          setOpenLoader(false);
          sessionStorage.setItem("@bookingCompleted", JSON.stringify(res));

          switch (res.status) {
            case 200:
              login();
              history.push("/busca/finalizado");
              break;

            case 201:
              if (res.data.auth2fa) {
                if (res.data.redirectAuth2fa !== null) {
                  setOpenLoader(true);
                  login();
                  window.location.replace(res.data.redirectAuth2fa);
                } else {
                  setBookingSuccessCode(res);
                  setPaymentData(data[type].paymentData);
                  setOpenModal2fa(true);
                }
              } else {
                login();
                history.push("/busca/finalizado");
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          setLoading(false);
          setOpenLoader(false);

          switch (err?.response?.status) {
            case 400:
              setError("Há algo de errado nos seus dados, verifique!");
              break;
            case 401:
              history.push("/session-expires");
              break;
            case 409:
              sessionStorage.setItem(
                "@bookingError",
                JSON.stringify(err.response.data.error)
              );
              sessionStorage.setItem(
                "reprocessar",
                JSON.stringify({ reprocessar: false })
              );
              history.push("/busca/booking-error");
              break;
            case 504:
              sessionStorage.setItem(
                "@bookingError",
                JSON.stringify("Tempo expirou, tente novamente!")
              );
              sessionStorage.setItem(
                "reprocessar",
                JSON.stringify({ reprocessar: false })
              );
              history.push("/busca/booking-error");
              break;
            default:
              setError(`Ocorreu algum problema, por favor, tente novamente!`);
              break;
          }
        });
    } else if (isFromHub) {
      const { data, type } = mountRequest();

      if (type === "bus") {
        await busRepository
          .postBusBooking(data)
          .then((res) => {
            setError("");
            setLoading(false);
            setOpenLoader(false);
            sessionStorage.setItem("@bookingCompleted", JSON.stringify(res));

            switch (res.status) {
              case 200:
                login();
                history.push("/busca/finalizado");
                break;

              case 201:
                if (res.data.auth2fa) {
                  if (res.data.redirectAuth2fa !== null) {
                    setOpenLoader(true);
                    login();
                    window.location.replace(res.data.redirectAuth2fa);
                  } else {
                    setBookingSuccessCode(res);
                    setPaymentData(data[type].paymentData);
                    setOpenModal2fa(true);
                  }
                } else {
                  login();
                  history.push("/busca/finalizado");
                }
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            setLoading(false);
            setOpenLoader(false);

            switch (err?.response?.status) {
              case 400:
                setError("Há algo de errado nos seus dados, verifique!");
                break;
              case 401:
                history.push("/session-expires");
                break;
              case 409:
                sessionStorage.setItem(
                  "@bookingError",
                  JSON.stringify(err.response.data.error)
                );
                sessionStorage.setItem(
                  "reprocessar",
                  JSON.stringify({ reprocessar: false })
                );
                history.push("/busca/booking-error");
                break;
              case 504:
                sessionStorage.setItem(
                  "@bookingError",
                  JSON.stringify("Tempo expirou, tente novamente!")
                );
                sessionStorage.setItem(
                  "reprocessar",
                  JSON.stringify({ reprocessar: false })
                );
                history.push("/busca/booking-error");
                break;
              default:
                setError(`Ocorreu algum problema, por favor, tente novamente!`);
                break;
            }
          });
      }
      if (type === "entertainment") {
        var bookingData = {};

        if (activityCategory === "transfer") {
          bookingData = {
            ...data,
            transferData: transferData,
          };
        } else {
          bookingData = {
            ...data,
          };
        }

        await entertainmentRepository
          .createBooking({ data: bookingData })
          .then((res) => {
            setError("");
            setLoading(false);
            setOpenLoader(false);
            sessionStorage.setItem(
              "@bookingCompleted",
              JSON.stringify(res.data)
            );

            switch (res.status) {
              case 200:
                login();
                history.push("/busca/finalizado");
                break;

              case 201:
                if (res.data.auth2fa) {
                  if (res.data.redirectAuth2fa !== null) {
                    setOpenLoader(true);
                    login();
                    window.location.replace(res.data.redirectAuth2fa);
                  } else {
                    setBookingSuccessCode(res);
                    setPaymentData(data[type].paymentData);
                    setOpenModal2fa(true);
                  }
                } else {
                  login();
                  history.push("/busca/finalizado");
                }
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            setLoading(false);
            setOpenLoader(false);

            switch (err?.response?.status) {
              case 400:
                setError("Há algo de errado nos seus dados, verifique!");
                break;
              case 401:
                history.push("/session-expires");
                break;
              case 409:
                sessionStorage.setItem(
                  "@bookingError",
                  JSON.stringify(err.response.data.error)
                );
                sessionStorage.setItem(
                  "reprocessar",
                  JSON.stringify({ reprocessar: false })
                );
                history.push("/busca/booking-error");
                break;
              case 500:
                sessionStorage.setItem(
                  "@bookingError",
                  JSON.stringify(err.response.data.error)
                );
                break;
              case 504:
                sessionStorage.setItem(
                  "@bookingError",
                  JSON.stringify("Tempo expirou, tente novamente!")
                );
                sessionStorage.setItem(
                  "reprocessar",
                  JSON.stringify({ reprocessar: false })
                );
                history.push("/busca/booking-error");
                break;
              default:
                setError(`Ocorreu algum problema, por favor, tente novamente!`);
                break;
            }
          });
      }
    }
  };
  useEffect(() => {
    setInstallmentsNumber(
      Array.from(
        { length: MAX_INSTALLMENTS },
        // { length: signed ? user.catalog.maximumInstallments : 10 },
        (_, i) => i + 1
      )
    );
  }, [signed, user]);

  useEffect(() => {
    cashTotalValue ? setPaymentInMoney(cashTotalValue) : setPaymentInMoney(0);
    pointsTotalValue
      ? setPaymentInPoints(pointsTotalValue)
      : setPaymentInPoints(0);
  }, [pointsTotalValue, cashTotalValue]);

  const closeModal2fa = () => setOpenModal2fa(false);

  const handleChangeCep = async (cep) => {
    if (cep?.length >= 8) {
      getCep(cep).then(({ data }) => {
        if (data.localidade) {
          setUserDataAddress({
            ...userDataAddress,
            zipCode: cep,
            city: data.localidade,
            state: ufs.find((u) => u.includes(`(${data.uf})`)),
            address: data.logradouro,
          });
        }
      });
    }
  };

  const verifyIfAnyGuestOrPassangerHaveTheSameNameAndLastName = () => {
    if (buscaAtual === "Hotel") {
      const guests = quartos.map((quarto) => quarto.hospedes);

      const guestsNameAndLastName = guests.map((guest) =>
        guest.map((g) => `${g.name} ${g.lastName}`)
      );

      const hasTheSameNameAndLastName = guestsNameAndLastName.map((guest) => {
        const hasTheSameNameAndLastName = guest.filter((g, index) =>
          guest.includes(g, index + 1)
        );

        return hasTheSameNameAndLastName.length > 0;
      });

      return hasTheSameNameAndLastName.includes(true);
    }

    if (buscaAtual !== "Hotel") {
      const convertedPassengers = Object.values(state.passengers);

      const passengersNameAndLastName = convertedPassengers.map(
        (passenger) => `${passenger.name} ${passenger.lastName}`
      );

      const hasTheSameNameAndLastName = passengersNameAndLastName.filter(
        (passenger, index) =>
          passengersNameAndLastName.includes(passenger, index + 1)
      );

      return hasTheSameNameAndLastName.length > 0;
    }
  };

  const handleKeyPress = (event, maxLength) => {
    const { value } = event.target;
    const charCode = event.which ? event.which : event.keyCode;

    if (value.length >= maxLength) {
      event.preventDefault();
    }
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || "Pontos");
  }, [user?.campaign?.campaignConfig?.coinAlias]);

  useEffect(() => {
    const inputs = document.querySelectorAll("input");

    inputs.forEach((input) => {
      input.addEventListener("change", (e) => {
        e.target.dispatchEvent(new Event("input", { bubbles: true }));
      });
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("change", (e) => {
          e.target.dispatchEvent(new Event("input", { bubbles: true }));
        });
      });
    };
  }, []);

  return (
    <S.Wrapper>
      <AppendHead>
        <script type="text/javascript" src={URL}></script>
      </AppendHead>
      <Portal>
        <noscript>
          <iframe
            title="iframeAntiFraud"
            style={{
              width: 100,
              height: 100,
              border: 0,
              position: "absolute",
              top: -5000,
            }}
            src={URL}
          ></iframe>
        </noscript>
      </Portal>

      <Loader
        openLoader={openLoader}
        isGeneral={isGeneralLoading}
        isBooking={!isGeneralLoading}
      />
      <Auth2faModal
        open={openModal2fa}
        onClose={closeModal2fa}
        booking={bookingSuccessCode}
        paymentData={paymentData}
      />
      <Container>
        <S.Content>
          <Grid container spacing={3}>
            {error && (
              <Grid item xs={12}>
                <Typography variant="span" color="error">
                  {error}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <S.Title>Detalhes da Viagem</S.Title>
            </Grid>
            <Grid item xs={12} md={9}>
              {buscaAtual === "Aéreo" && (
                <>
                  <FlightSummaryCard
                    title="Ida"
                    category={{ detalhes: {}, name: "Light" }}
                    flightCode={`${goingFlight?.validatingcarrier}${goingFlight?.flights[0].flightnumber}`}
                    departureDate={goingFlight?.departuredatetime}
                    arrivalDate={goingFlight?.arrivaldatetime}
                    companyLogo={
                      goingFlight &&
                      getCompanyLogos(goingFlight?.validatingcarrier)
                    }
                    departureAirport={goingFlight?.departurestation}
                    arrivalAirport={goingFlight?.arrivalstation}
                    airplaneFacilities={{
                      wifi: true,
                      share: true,
                      video: true,
                    }}
                    airplaneModel="Airbus A320"
                    company={goingFlight?.validatingcarrier}
                    fareFamily={goingFlight?.flights[0].classofservices[0]}
                    steps={goingFlight?.flights}
                    baggage={
                      goingFlight?.flights[0].fareoption?.baggageallowance
                    }
                    buscaAtual={buscaAtual}
                  />
                  {dataSelected?.return && (
                    <FlightSummaryCard
                      title="Volta"
                      category={{ detalhes: {}, name: "Light" }}
                      flightCode={`${returnFlight?.validatingcarrier}${returnFlight?.flights[0].flightnumber}`}
                      departureDate={returnFlight?.departuredatetime}
                      arrivalDate={returnFlight?.arrivaldatetime}
                      companyLogo={
                        returnFlight &&
                        getCompanyLogos(returnFlight.validatingcarrier)
                      }
                      departureAirport={returnFlight?.departurestation}
                      arrivalAirport={returnFlight?.arrivalstation}
                      airplaneFacilities={{
                        wifi: true,
                        share: true,
                        video: true,
                      }}
                      airplaneModel="Airbus A320"
                      company={returnFlight?.validatingcarrier}
                      fareFamily={returnFlight?.flights[0].classofservices[0]}
                      steps={returnFlight?.flights}
                      baggage={
                        returnFlight?.flights[0].fareoption?.baggageallowance
                      }
                      buscaAtual={buscaAtual}
                    />
                  )}
                </>
              )}
              {buscaAtual === "Carro" && (
                <>
                  <CarSummaryCard
                    car={dataSelected}
                    searchState={searchState}
                    buscaAtual={buscaAtual}
                  />
                </>
              )}

              {buscaAtual === "Hotel" && (
                <>
                  {searchState && (
                    <HotelSummaryCard
                      hotel={dataSelected}
                      buscaAtual={buscaAtual}
                    />
                  )}

                  {searchState &&
                    Array(searchState.qtRooms)
                      .fill()
                      .map((_, idx) => idx)
                      .map((quarto) => (
                        <S.BlankCard>
                          <div className="title">
                            <S.Title>Quarto {quarto + 1}</S.Title>
                          </div>
                          <Grid container spacing={1}>
                            {passengers.map((pass, index) => {
                              return (
                                <>
                                  <Grid item xs={12}>
                                    <span>{pass.label}</span>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormInput
                                      maxLength={28}
                                      onChange={(e) => {
                                        if (
                                          e.target.name === "name" ||
                                          e.target.name === "lastName"
                                        ) {
                                          const normalizedValue = e.target.value
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")
                                            .replace(/[^a-zA-Z ]/g, "")
                                            .toUpperCase();

                                          e.target.value = normalizedValue;
                                        }
                                        e.target.value !== "" &&
                                          onChangeRoomGuests({
                                            quarto: quarto,
                                            hospede: pass.index,
                                            prop: e.target.name,
                                            target: e.target,
                                            value: e.target.value,
                                          });
                                      }}
                                      label="Nome"
                                      name={`name`}
                                      error={
                                        send &&
                                        !quartos[quarto]?.hospedes.find(
                                          (h) => h.hospede === pass.index
                                        )?.name
                                      }
                                      helperText={
                                        send &&
                                        !quartos[quarto]?.hospedes.find(
                                          (h) => h.hospede === pass.index
                                        )?.name
                                          ? "Nome não pode ser vazio"
                                          : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormInput
                                      maxLength={28}
                                      onChange={(e) => {
                                        if (
                                          e.target.name === "name" ||
                                          e.target.name === "lastName"
                                        ) {
                                          const normalizedValue = e.target.value
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")
                                            .replace(/[^a-zA-Z ]/g, "")
                                            .toUpperCase();
                                          e.target.value = normalizedValue;
                                        }
                                        e.target.value !== "" &&
                                          onChangeRoomGuests({
                                            quarto: quarto,
                                            hospede: pass.index,
                                            prop: e.target.name,
                                            target: e.target,
                                            value: e.target.value,
                                          });
                                      }}
                                      label="Sobrenome"
                                      name={`lastName`}
                                      error={
                                        send &&
                                        !quartos[quarto]?.hospedes.find(
                                          (h) => h.hospede === pass.index
                                        )?.lastName
                                      }
                                      helperText={
                                        send &&
                                        !quartos[quarto]?.hospedes.find(
                                          (h) => h.hospede === pass.index
                                        )?.lastName
                                          ? "Sobrenome não pode ser vazio"
                                          : ""
                                      }
                                    />
                                  </Grid>
                                  {pass.label.substring(0, 7) === "Criança" && (
                                    <Grid item xs={12} sm={6}>
                                      <FormInput
                                        mask="99/99/9999"
                                        onChange={(e) => {
                                          e.target.value !== "" &&
                                            onChangeRoomGuests({
                                              quarto: quarto,
                                              hospede: pass.index,
                                              prop: e.target.name,
                                              value: e.target.value,
                                            });
                                        }}
                                        label="Data Nascimento"
                                        name="birthdate"
                                        error={
                                          send &&
                                          !quartos[quarto]?.hospedes.find(
                                            (h) => h.hospede === pass.index
                                          )?.birthdate
                                        }
                                        helperText={
                                          send &&
                                          !quartos[quarto]?.hospedes.find(
                                            (h) => h.hospede === pass.index
                                          )?.birthdate
                                            ? "Data Nascimento não pode ser vazio"
                                            : ""
                                        }
                                      />
                                    </Grid>
                                  )}
                                  {quarto === 0 && pass.index === 0 && (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <FormInput
                                          mask="99/99/9999"
                                          onChange={(e) => {
                                            e.target.value !== "" &&
                                              onChangeRoomGuests({
                                                quarto: quarto,
                                                hospede: pass.index,
                                                prop: e.target.name,
                                                value: e.target.value,
                                              });
                                          }}
                                          label="Data Nascimento"
                                          name="birthdate"
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <FormInput
                                          onBlur={(e) => {
                                            e.target.value !== "" &&
                                              onChangeRoomGuests({
                                                quarto: quarto,
                                                hospede: pass.index,
                                                prop: e.target.name,
                                                value: e.target.value,
                                              });
                                          }}
                                          select
                                          name="documentType"
                                          label="Tipo Documento"
                                          error={
                                            send &&
                                            !quartos[quarto]?.hospedes.find(
                                              (h) => h.hospede === pass.index
                                            )?.documentType
                                          }
                                          helperText={
                                            send &&
                                            !quartos[quarto]?.hospedes.find(
                                              (h) => h.hospede === pass.index
                                            )?.documentType
                                              ? "Tipo Documento não pode ser vazio"
                                              : ""
                                          }
                                        >
                                          <MenuItem key={0} value="CPF">
                                            CPF
                                          </MenuItem>
                                          <MenuItem key={1} value="CNH">
                                            CNH
                                          </MenuItem>
                                          <MenuItem key={2} value="RG">
                                            RG
                                          </MenuItem>
                                        </FormInput>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <FormInput
                                          onBlur={(e) => {
                                            e.target.value !== "" &&
                                              onChangeRoomGuests({
                                                quarto: quarto,
                                                hospede: pass.index,
                                                prop: e.target.name,
                                                value: e.target.value,
                                              });
                                          }}
                                          label="Número documento"
                                          name="documentId"
                                          error={
                                            send &&
                                            !quartos[quarto]?.hospedes.find(
                                              (h) => h.hospede === pass.index
                                            )?.documentId
                                          }
                                          helperText={
                                            send &&
                                            !quartos[quarto]?.hospedes.find(
                                              (h) => h.hospede === pass.index
                                            )?.documentId
                                              ? "Número documento não pode ser vazio"
                                              : ""
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <FormInput
                                          onBlur={(e) => {
                                            e.target.value !== "" &&
                                              onChangeRoomGuests({
                                                quarto: quarto,
                                                hospede: pass.index,
                                                prop: e.target.name,
                                                value: e.target.value,
                                              });
                                          }}
                                          label="E-mail"
                                          name="email"
                                          error={
                                            send &&
                                            !validEmail(
                                              quartos[quarto]?.hospedes.find(
                                                (h) => h.hospede === pass.index
                                              )?.email
                                            )
                                          }
                                          helperText={
                                            send &&
                                            !validEmail(
                                              quartos[quarto]?.hospedes.find(
                                                (h) => h.hospede === pass.index
                                              )?.email
                                            )
                                              ? "E-mail precisa ser preenchido com um e-mail valido"
                                              : ""
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <FormInput
                                          mask="(99) 99999-9999"
                                          onBlur={(e) => {
                                            e.target.value !== "" &&
                                              onChangeRoomGuests({
                                                quarto: quarto,
                                                hospede: pass.index,
                                                prop: e.target.name,
                                                value: e.target.value,
                                              });
                                          }}
                                          label="Telefone"
                                          name="phone"
                                          error={
                                            send &&
                                            !quartos[quarto]?.hospedes.find(
                                              (h) => h.hospede === pass.index
                                            )?.phone
                                          }
                                          helperText={
                                            send &&
                                            !quartos[quarto]?.hospedes.find(
                                              (h) => h.hospede === pass.index
                                            )?.phone
                                              ? "Telefone não pode ser vazio"
                                              : ""
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </Grid>
                        </S.BlankCard>
                      ))}
                </>
              )}
              {buscaAtual === "Rodoviário" && (
                <>
                  <BusSummaryCard
                    searchState={searchState}
                    goingRoute={goingRoute}
                    returnRoute={returnRoute}
                    openServiceTermsAndPrivacyPolicyBusModal={
                      setOpenServiceTermsAndPrivacyPolicyBusModal
                    }
                    selectedGoingSeats={selectedGoingSeats}
                    setSelectedGoingSeats={setSelectedGoingSeats}
                    selectedReturnSeats={selectedReturnSeats}
                    setSelectedReturnSeats={setSelectedReturnSeats}
                    result={result}
                    seatMapIda={seatMapIda}
                    seatMapVolta={seatMapVolta}
                    loading={loadingSeats}
                  ></BusSummaryCard>
                </>
              )}
              {buscaAtual !== "Hotel" &&
                passengers.map((pass) => (
                  <S.BlankCard>
                    <div className="title">
                      <S.Title>
                        {buscaAtual === "Carro"
                          ? `Dados do motorista`
                          : `Dados do Passageiro ${pass.index + 1} (${
                              pass.tipo
                            })`}
                      </S.Title>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          maxLength={28}
                          label="Nome"
                          name="name"
                          error={send && !state.passengers[pass.index].name}
                          helperText={
                            send && !state.passengers[pass.index].name
                              ? "Nome não pode ser vazio"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          maxLength={28}
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          label="Sobrenome"
                          name="lastName"
                          error={send && !state.passengers[pass.index].lastName}
                          helperText={
                            send && !state.passengers[pass.index].lastName
                              ? "Sobrenome não pode ser vazio"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          mask="99/99/9999"
                          onChange={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          label="Data Nascimento"
                          name="birthdate"
                          error={
                            send && !state.passengers[pass.index].birthdate
                          }
                          helperText={
                            send && !state.passengers[pass.index].birthdate
                              ? "Data de nascimento não pode ser vazio"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          select
                          label="Sexo"
                          name="gender"
                          value={state.passengers[pass.index].gender}
                          error={send && !state.passengers[pass.index].gender}
                          helperText={
                            send && !state.passengers[pass.index].gender
                              ? "Genero não pode ser vazio"
                              : ""
                          }
                        >
                          <MenuItem key={0} value="M">
                            Masculino
                          </MenuItem>
                          <MenuItem key={1} value="F">
                            Feminino
                          </MenuItem>
                          <MenuItem key={2} value="O">
                            Outro
                          </MenuItem>
                        </FormInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          select
                          name="documentType"
                          label="Tipo Documento"
                          value={state.passengers[pass.index].documentType}
                          error={
                            send && !state.passengers[pass.index].documentType
                          }
                          helperText={
                            send && !state.passengers[pass.index].documentType
                              ? "Tipo de documento não pode ser vazio"
                              : ""
                          }
                        >
                          <MenuItem key={0} value="CPF">
                            CPF
                          </MenuItem>
                          <MenuItem key={1} value="CNH">
                            CNH
                          </MenuItem>
                          <MenuItem key={2} value="RG">
                            RG
                          </MenuItem>
                        </FormInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onKeyPress={(e) => handleKeyPress(e, 11)}
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          label="Número documento"
                          name="documentId"
                          error={
                            send && !state.passengers[pass.index].documentId
                          }
                          helperText={
                            send && !state.passengers[pass.index].documentId
                              ? "Documento não pode ser vazio"
                              : ""
                          }
                          inputProps={{ maxLength: 11 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          label="E-mail"
                          name="email"
                          error={
                            send &&
                            !validEmail(state?.passengers[pass.index]?.email)
                          }
                          helperText={
                            send &&
                            !validEmail(state?.passengers[pass.index]?.email)
                              ? "E-mail precisa ser preenchido com um e-mail valido"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          mask="(99) 99999-9999"
                          onBlur={(e) =>
                            onChangePassengers(pass.tipo, pass.index, e)
                          }
                          label="Telefone"
                          name="phone"
                          error={send && !state.passengers[pass.index].phone}
                          helperText={
                            send && !state.passengers[pass.index].phone
                              ? "Telefone não pode ser vazio"
                              : ""
                          }
                        />
                      </Grid>
                      {(buscaAtual === "Experiencias" ||
                        buscaAtual === "Carro") && (
                        <Grid item xs={12} sm={12}>
                          <FormInput
                            onBlur={(e) =>
                              onChangePassengers(pass.tipo, pass.index, e)
                            }
                            label="Endereço completo"
                            name="address"
                            error={
                              send && !state.passengers[pass.index].address
                            }
                            helperText={
                              send && !state.passengers[pass.index].address
                                ? "Endereço não pode ser vazio"
                                : ""
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </S.BlankCard>
                ))}
              {buscaAtual === "Entretenimento" &&
                activityCategory === "transfer" && (
                  <S.BlankCard>
                    <div className="title">
                      <S.Title>Dados do transfer:</S.Title>
                    </div>
                    <span>
                      Para que sua experiência seja realizada, é necessário
                      completar os dados solicitados a seguir.
                    </span>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="radio-group">
                          <span>
                            Você vai iniciar o serviço em um Aeroporto? *
                          </span>
                          <div className="radio-options">
                            <label>
                              <input
                                type="radio"
                                name="iniciaAeroporto"
                                value="sim"
                                checked={isStartingAirport}
                                onChange={() => setIsStartingAirport(true)}
                              />
                              Sim
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="iniciaAeroporto"
                                value="nao"
                                checked={!isStartingAirport}
                                onChange={() => setIsStartingAirport(false)}
                              />
                              Não
                            </label>
                          </div>
                          {isStartingAirport ? (
                            <div className="airport-details">
                              <FormInput
                                label="Nome ou sigla do Aeroporto de início"
                                style={{ minWidth: "300px" }}
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    startingAirport: {
                                      ...prev.startingAirport,
                                      name: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.startingAirport.name &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.startingAirport.name &&
                                  isDestinationAirport === true
                                    ? "Nome ou sigla do Aeroporto de início não pode ser vazio"
                                    : ""
                                }
                              />
                              <FormInput
                                label="Código da reserva"
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    startingAirport: {
                                      ...prev.startingAirport,
                                      reservationCode: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.startingAirport
                                    .reservationCode &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.startingAirport
                                    .reservationCode &&
                                  isDestinationAirport === true
                                    ? "Código da reserva não pode ser vazio"
                                    : ""
                                }
                              />
                              <FormInput
                                label="Núm. do vôo de início"
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    startingAirport: {
                                      ...prev.startingAirport,
                                      flightNumber: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.startingAirport.flightNumber &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.startingAirport.flightNumber &&
                                  isDestinationAirport === true
                                    ? "Número do vôo de início não pode ser vazio"
                                    : ""
                                }
                              />
                              <FormInput
                                label="Hora de chegada do vôo"
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    startingAirport: {
                                      ...prev.startingAirport,
                                      arrivalTime: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.startingAirport.arrivalTime &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.startingAirport.arrivalTime &&
                                  isDestinationAirport === true
                                    ? "Hora de chegada do vôo de início não pode ser vazio"
                                    : ""
                                }
                              />
                            </div>
                          ) : (
                            <div className="airport-details">
                              <FormInput
                                label="Nome e endereço do local onde o Transfer irá te encontrar"
                                style={{ minWidth: "100%" }}
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    pickupLocation: e.target.value,
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.pickupLocation &&
                                  isDestinationAirport === false
                                }
                                helperText={
                                  send &&
                                  !transferData.pickupLocation &&
                                  isDestinationAirport === false
                                    ? "Nome e endereço do local onde o Transfer irá te encontrar não pode ser vazio"
                                    : ""
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="radio-group">
                          <span>O seu destino será um Aeroporto? *</span>
                          <div className="radio-options">
                            <label>
                              <input
                                type="radio"
                                name="destinoAeroporto"
                                value="sim"
                                checked={isDestinationAirport}
                                onChange={() => setIsDestinationAirport(true)}
                              />
                              Sim
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="destinoAeroporto"
                                value="nao"
                                checked={!isDestinationAirport}
                                onChange={() => setIsDestinationAirport(false)}
                              />
                              Não
                            </label>
                          </div>
                          {isDestinationAirport ? (
                            <div className="airport-details">
                              <FormInput
                                label="Nome ou sigla do Aeroporto de destino"
                                style={{ minWidth: "300px" }}
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    destinationAirport: {
                                      ...prev.destinationAirport,
                                      name: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.destinationAirport.name &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.destinationAirport.name &&
                                  isDestinationAirport === true
                                    ? "Nome ou sigla do Aeroporto de destino não pode ser vazio"
                                    : ""
                                }
                              />
                              <FormInput
                                label="Código da reserva"
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    destinationAirport: {
                                      ...prev.destinationAirport,
                                      reservationCode: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.destinationAirport
                                    .reservationCode &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.destinationAirport
                                    .reservationCode &&
                                  isDestinationAirport === true
                                    ? "Código da reserva não pode ser vazio"
                                    : ""
                                }
                              />
                              <FormInput
                                label="Núm. do vôo de destino"
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    destinationAirport: {
                                      ...prev.destinationAirport,
                                      flightNumber: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.destinationAirport
                                    .flightNumber &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.destinationAirport
                                    .flightNumber &&
                                  isDestinationAirport === true
                                    ? "Número do vôo de destino não pode ser vazio"
                                    : ""
                                }
                              />
                              <FormInput
                                label="Hora de chegada do vôo"
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    destinationAirport: {
                                      ...prev.destinationAirport,
                                      arrivalTime: e.target.value,
                                    },
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.destinationAirport
                                    .arrivalTime &&
                                  isDestinationAirport === true
                                }
                                helperText={
                                  send &&
                                  !transferData.destinationAirport
                                    .arrivalTime &&
                                  isDestinationAirport === true
                                    ? "Hora de chegada do vôo de destino não pode ser vazio"
                                    : ""
                                }
                              />
                            </div>
                          ) : (
                            <div className="airport-details">
                              <FormInput
                                label="Nome e endereço do local onde o Transfer irá te levar"
                                style={{ minWidth: "100%" }}
                                onChange={(e) =>
                                  setTransferData((prev) => ({
                                    ...prev,
                                    dropOffLocation: e.target.value,
                                  }))
                                }
                                error={
                                  send &&
                                  !transferData.dropOffLocation &&
                                  isDestinationAirport === false
                                }
                                helperText={
                                  send &&
                                  !transferData.dropOffLocation &&
                                  isDestinationAirport === false
                                    ? "Nome e endereço do local onde o Transfer irá te levar não pode ser vazio"
                                    : ""
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </S.BlankCard>
                )}
              {user?.campaign?.acceptCashPayment && (
                <>
                  {!user?.guest && !user?.campaign?.disablePointActivity && (
                    <S.BlankCard>
                      <div className="title">
                        <S.Title>Pagamento em</S.Title>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            disabled={discountPoints ? true : false}
                            type="number"
                            label={`Valor do Saldo`}
                            value={paymentInPoints}
                            error={
                              paymentInPoints < minimumPointsToBuy ||
                              paymentInPoints > userPoints
                            }
                            helperText={
                              paymentInPoints > userPoints
                                ? `Valor em ${coinAlias} maior que seu saldo de ${coinAlias}`
                                : paymentInPoints < minimumPointsToBuy &&
                                  `Quantidade de ${coinAlias} não pode ser menor que ${minimumPointsToBuy}`
                            }
                            onChange={(e) => {
                              if (e.target.value <= pointsPrice) {
                                setPaymentInPoints(e.target.value);
                                if (e.target.value >= pointsPrice) {
                                  setPaymentInMoney(0);
                                } else {
                                  castPointsToMoney(e.target.value);
                                }
                              }
                            }}
                          />
                          {/* {discountPoints ? (
                        <Typography variant='span' style={{ color: 'green' }}>
                          Desconto aplicado de:{' '}
                          <strong>{discountPoints} pontos</strong>
                        </Typography>
                      ) : (
                        <></>
                      )} */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            type="number"
                            label="Valor adicional em R$"
                            value={paymentInMoney}
                            disabled={discountPoints ? true : false}
                            onChange={(e) => {
                              if (parseFloat(e.target.value).toFixed(2) >= 0) {
                                if (
                                  parseFloat(e.target.value).toFixed(2) <=
                                  cashPrice
                                ) {
                                  setPaymentInMoney(
                                    parseFloat(e.target.value).toFixed(2)
                                  );
                                  castMoneyToPoints(
                                    parseFloat(e.target.value).toFixed(2)
                                  );
                                }
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </S.BlankCard>
                  )}
                </>
              )}
              {user?.campaign?.couponEnabled && (
                <S.BlankCard>
                  <div className="title">
                    <S.Title>Cupom de desconto</S.Title>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormInput
                        onChange={(e) => {
                          setCouponError("");
                          setDiscountCoupon(e.target.value);
                        }}
                        label="Digite um cupom de desconto"
                        name="discountCoupon"
                        value={discountCoupon}
                        disabled={discountPoints || discountCash}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ThemeButton
                        onClick={() => {
                          setIsGeneralLoading(true);
                          setLoading(true);
                          setOpenLoader(true);
                          validateCoupon(discountCoupon);
                        }}
                        className={`payment coupon`}
                        disabled={
                          discountCoupon
                            ? discountPoints || discountCash
                              ? true
                              : false
                            : true
                        }
                      >
                        Aplicar cupom
                      </ThemeButton>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ThemeButton
                        onClick={() => removeCoupon()}
                        className={`payment coupon`}
                        disabled={discountPoints || discountCash ? false : true}
                      >
                        Remover cupom
                      </ThemeButton>
                    </Grid>
                    {couponError && (
                      <Grid item xs={12}>
                        <Typography variant="span" color="error">
                          {couponError}
                        </Typography>
                      </Grid>
                    )}
                    {!couponError && discountPoints > 0 && (
                      <Grid item xs={12}>
                        <Typography variant="span" style={{ color: "green" }}>
                          Cupom aplicado com sucesso!
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </S.BlankCard>
              )}
              {user?.campaign?.acceptCashPayment && paymentInMoney > 0 && (
                <>
                  <S.BlankCard>
                    <div className="title">
                      <S.Title>Forma de Pagamento</S.Title>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <CardFlags />
                      </Grid>
                      {userCardDataSaved.length > 0 && (
                        <Grid item xs={6}>
                          {savedCard(
                            userCardDataSaved[0].brand,
                            userCardDataSaved[0].last4Digits
                          )}
                        </Grid>
                      )}
                    </Grid>

                    {!useSavedCard && (
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            onChange={onChangeField}
                            label="Nome do Titular do Cartão"
                            name="cardHolder"
                            error={!useSavedCard && send && !state.cardHolder}
                            helperText={
                              !useSavedCard && send && !state.cardHolder
                                ? "Nome do titular do cartão não pode ser vazio"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            onChange={onChangeField}
                            label="Número do Cartão"
                            name="cardNumber"
                            error={!useSavedCard && send && !state.cardNumber}
                            helperText={
                              !useSavedCard && send && !state.cardNumber
                                ? "Número do cartão não pode ser vazio"
                                : ""
                            }
                          />
                          {state.cardNumber && (
                            <label className="cardFlag">
                              {cardFlag.getCardFlag(state?.cardNumber)}
                            </label>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            mask="99/9999"
                            onChange={onChangeField}
                            label="Data de expiração"
                            name="cardExpiration"
                            error={
                              !useSavedCard && send && !state.cardExpiration
                            }
                            helperText={
                              !useSavedCard && send && !state.cardExpiration
                                ? "Data de expiração do cartão não pode ser vazio"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            onChange={onChangeField}
                            label="Código CVV"
                            name="cardCCV"
                            error={!useSavedCard && send && !state.cardCCV}
                            helperText={
                              !useSavedCard && send && !state.cardCCV
                                ? "CCV do cartão não pode ser vazio"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormInput
                            onChange={onChangeField}
                            label="Número de parcelas"
                            name="installments"
                            select
                            error={!useSavedCard && send && !state.installments}
                            helperText={
                              !useSavedCard && send && !state.installments
                                ? "Número de parcelas não pode ser vazio"
                                : ""
                            }
                          >
                            {installmentsNumber.map((installment) => (
                              <MenuItem key={installment} value={installment}>
                                {installment}
                              </MenuItem>
                            ))}
                          </FormInput>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label='Salvar cartão para uso futuro?'
                          control={
                            <Checkbox
                              checked={saveCardData}
                              onChange={() => setSaveCardData(!saveCardData)}
                            />
                          }
                        />
                      </Grid> */}
                      </Grid>
                    )}
                  </S.BlankCard>
                  <S.BlankCard>
                    <div className="title">
                      <S.Title>Dados de cobrança</S.Title>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          label="CPF"
                          name="documentNumber"
                          error={send && !userDataAddress.documentNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          label="E-mail"
                          name="email"
                          error={send && !userDataAddress.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          mask="(99) 99999-9999"
                          onChange={onChangeFieldCobranca}
                          label="Celular"
                          name="mobilePhoneNumber"
                          error={send && !userDataAddress.mobilePhoneNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          onBlur={(e) => handleChangeCep(e.target.value)}
                          label="CEP"
                          name="zipCode"
                          error={send && !userDataAddress.zipCode}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          label="Endereço"
                          name="address"
                          value={userDataAddress.address}
                          error={send && !userDataAddress.address}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          label="Número endereço"
                          name="number"
                          error={send && !userDataAddress.number}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          select
                          label="Estado"
                          name="state"
                          value={userDataAddress.state}
                          error={send && !userDataAddress.state}
                        >
                          {ufs.map((uf, index) => (
                            <MenuItem value={uf} key={index}>
                              {uf}
                            </MenuItem>
                          ))}
                        </FormInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          label="Cidade"
                          name="city"
                          value={userDataAddress.city}
                          error={send && !userDataAddress.city}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          onChange={onChangeFieldCobranca}
                          label="Complemento"
                          name="complement"
                          //error={send && !userDataAddress.complement}
                        />
                      </Grid>
                    </Grid>
                  </S.BlankCard>
                </>
              )}
              <S.AlertStyle>
                <Alert variant="filled" severity="info">
                  <span className="text">
                    Clicando em ‘Comprar', você está concordando com nossos{" "}
                    <Link
                      component="button"
                      variant="body2"
                      color="inherit"
                      onClick={() => {
                        setOpenServiceTermsAndPrivacyPolicyModal(true);
                      }}
                    >
                      termos de uso e política de confidencialidade
                    </Link>{" "}
                    e com a possibilidade de entrarmos em contato pelos meios
                    disponíveis, inclusive via WhatsApp.
                  </span>
                </Alert>
              </S.AlertStyle>
              {openServiceTermsAndPrivacyPolicyModal && (
                <SimpleModal
                  opened={openServiceTermsAndPrivacyPolicyModal}
                  openedState={setOpenServiceTermsAndPrivacyPolicyModal}
                  title={useTerms?.description}
                  text={useTerms?.content}
                  closeButtonText="Declaro ter lido os termos"
                  allowCloseByClickOutside={false}
                  closeButtonDisabledTillScrollEnded={true}
                />
              )}
              {openServiceTermsAndPrivacyPolicyBusModal && (
                <SimpleBusModal
                  opened={openServiceTermsAndPrivacyPolicyBusModal}
                  openedState={setOpenServiceTermsAndPrivacyPolicyBusModal}
                  policies={policies}
                  closeButtonText="Declaro ter lido as políticas da companhia"
                  allowCloseByClickOutside={false}
                  closeButtonDisabledTillScrollEnded={true}
                />
              )}
              <ThemeButton
                onClick={() => {
                  sending();
                  onSubmit();
                }}
                className={`payment ${
                  signed
                    ? userPoints < minimumPointsToBuy
                      ? "disabled"
                      : ""
                    : "disabled"
                }`}
                disabled={signed ? userPoints < minimumPointsToBuy : true}
              >
                Comprar
              </ThemeButton>
            </Grid>
            <Grid item xs={12} md={3}>
              {buscaAtual === "Aéreo" && (
                <>
                  <FlightSideSumary
                    goingFlight={goingFlight}
                    returnFlight={returnFlight}
                    recommendation={dataSelected?.recommendation}
                    onSubmit={onSubmit}
                    sending={sending}
                    ticket={pointsPrice - fees}
                    taxaEmbarque={fees}
                    total={paymentInPoints}
                    totalApagar={paymentInMoney}
                    cashPrice={cashPrice}
                    pointsPrice={pointsPrice}
                    pointsTotalValue={pointsTotalValue}
                    cashTotalValue={cashTotalValue}
                    userPoints={userPoints}
                    minimumPointsToBuy={minimumPointsToBuy}
                    discountPoints={discountPoints}
                    discountCash={discountCash}
                    buscaAtual={buscaAtual}
                    campaignDiscountPoints={campaignDiscountPoints}
                    campaignDiscountCash={campaignDiscountCash}
                    discountPercentage={discountPercentage}
                  />
                </>
              )}
              {buscaAtual === "Carro" && (
                <CarSideSummary
                  car={dataSelected}
                  total={paymentInPoints}
                  totalApagar={paymentInMoney}
                  cashPrice={cashPrice}
                  pointsPrice={pointsPrice}
                  pointsTotalValue={pointsTotalValue}
                  cashTotalValue={cashTotalValue}
                  userPoints={userPoints}
                  minimumPointsToBuy={minimumPointsToBuy}
                  discountPoints={discountPoints}
                  discountCash={discountCash}
                  buscaAtual={buscaAtual}
                />
              )}
              {buscaAtual === "Hotel" && searchState && (
                <HotelSideSummary
                  hotel={dataSelected}
                  total={paymentInPoints}
                  totalApagar={paymentInMoney}
                  cashPrice={cashPrice}
                  pointsPrice={pointsPrice}
                  pointsTotalValue={pointsTotalValue}
                  cashTotalValue={cashTotalValue}
                  userPoints={userPoints}
                  minimumPointsToBuy={minimumPointsToBuy}
                  discountPoints={discountPoints}
                  discountCash={discountCash}
                  buscaAtual={buscaAtual}
                />
              )}
              {buscaAtual === "Experiencias" && (
                <ExperienceSideSummary
                  experience={dataSelected}
                  total={paymentInPoints}
                  totalApagar={paymentInMoney}
                  cashPrice={cashPrice}
                  pointsPrice={pointsPrice}
                  pointsTotalValue={pointsTotalValue}
                  cashTotalValue={cashTotalValue}
                  userPoints={userPoints}
                  minimumPointsToBuy={minimumPointsToBuy}
                  discountPoints={discountPoints}
                  discountCash={discountCash}
                  buscaAtual={buscaAtual}
                />
              )}
              {buscaAtual === "Rodoviário" && (
                <BusSideSummary
                  taxaEmbarque={fees}
                  userPoints={userPoints}
                  setCashPrice={setCashPrice}
                  setPointsPrice={setPointsPrice}
                  pointsTotalValue={pointsTotalValue}
                  cashTotalValue={cashTotalValue}
                  minimumPointsToBuy={minimumPointsToBuy}
                  searchState={searchState}
                  goingRoute={goingRoute}
                  returnRoute={returnRoute}
                  selectedGoingSeats={selectedGoingSeats}
                  selectedReturnSeats={selectedReturnSeats}
                  paymentInPoints={paymentInPoints}
                  paymentInMoney={paymentInMoney}
                ></BusSideSummary>
              )}
              {buscaAtual === "Entretenimento" && (
                <EntertainmentSideSummary
                  activityCategory={activityCategory}
                  userPoints={userPoints}
                  cashTotalValue={cashTotalValue}
                  setPointsPrice={setPointsPrice}
                  minimumPointsToBuy={minimumPointsToBuy}
                  paymentInPoints={paymentInPoints}
                  paymentInMoney={paymentInMoney}
                ></EntertainmentSideSummary>
              )}
            </Grid>
          </Grid>
        </S.Content>
      </Container>
    </S.Wrapper>
  );
};

export default SummaryContent;
