import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import * as S from '../styles';
import { Grid } from '@material-ui/core';
import ThemeButton from '../../../components/ThemeButton';
import { addDays, addMonths, isAfter, parse } from 'date-fns';
import { useGlobal } from '../../../contexts/globalContext';
import { pt } from 'react-date-range/dist/locale';
function ReturnDateModal({
	open,
	onClose,
	arrivalDate,
	setReturnDateFromModal,
}) {
	const [date, setDate] = useState(null);
	const { showAlert /** , signed **/ } = useGlobal();

	const handleSelectedDate = () => {
		try {
			if (!date) {
				showAlert('error', 'Por favor, selecione uma data de retorno.');
				return;
			}

			setReturnDateFromModal(date);
		} catch (error) {
			console.error('Erro ao formatar data:', error.message);
			showAlert('error', 'Data inválida, por favor selecione uma data válida.');
			return;
		}
	};
	return (
		<>
			{arrivalDate ? (
				<S.Modal>
					<Dialog
						open={open}
						maxWidth='sm'
						fullWidth={true}
						// onClose={() => onClose(false)}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogContent dividers>
							<S.ModalWrapper>
								<Grid container direction='row' spacing={2}>
									<Grid item xs={12} md={12} lg={12}>
										<div className='title'>
											<span>Selecione a data de retorno</span>
										</div>
									</Grid>
									<S.GridFlexible
										item
										sm={12}
										md={12}
										lg={12}
										className='modal-content-container'
									>
										<div className='calendar'>
											<S.DateCalendar
												onChange={(item) => {
													setDate(item);
												}}
												locale={pt}
												date={
													date || addDays(parse(arrivalDate, 'dd/MM/yyyy', new Date()), 1)
												}
												minDate={addDays(parse(arrivalDate, 'dd/MM/yyyy', new Date()), 1)}
												maxDate={addMonths(new Date(), 12)}
												dayContentRenderer={(date) => {
													const isAvailable = isAfter(
														date,
														parse(arrivalDate, 'dd/MM/yyyy', new Date())
													);

													if (isAvailable) {
														return (
															<div
																style={{
																	opacity: 1,
																	pointerEvents: 'auto',
																	color: '#333',
																	padding: '5px',
																}}
															>
																{date.getDate()}{' '}
															</div>
														);
													}

													return (
														<div
															style={{
																opacity: 0.5,
																pointerEvents: 'none',
																color: '#d3d3d3',
																padding: '5px',
															}}
														>
															{date.getDate()}{' '}
														</div>
													);
												}}
											/>
										</div>
										<ThemeButton
											onClick={() => {
												handleSelectedDate();
												onClose(false);
											}}
											className='modal-calendar-button choose-entertainment'
										>
											Escolhi minha data de retorno
										</ThemeButton>
									</S.GridFlexible>
								</Grid>
							</S.ModalWrapper>
						</DialogContent>
					</Dialog>
				</S.Modal>
			) : (
				<></>
			)}
		</>
	);
}

export default ReturnDateModal;
