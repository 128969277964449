import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TicketActivity from "./TicketActivity";
import TourActivity from "./TourActivity";
import TransferActivity from "./TransferActivity";
import { getSessionStorage } from "../../utils/getSessionStorage";

function EntertainmentDetails() {
	const [selectedActivity, setSelectedActivity] = useState(
		getSessionStorage('@EntertainmentSelected') || {}
	);
	const [activity, setActivity] = useState(
		getSessionStorage('@EntertainmentSelected')?.activity || {}
	);
	const [availabilityData, setAvailabilityData] = useState(
		getSessionStorage('@EntertainmentAvailabilitySelected') || {}
	);

	const isActivityValid = (activityId) => {
		return activityId && !activityId.endsWith('_0');
	};

	return (
		<div>
			{activity ? (
				<>
					{activity.ticket && isActivityValid(activity.ticket.activityId) && (
						<TicketActivity
							activity={activity.ticket}
							preSelectedActivity={selectedActivity}
							preSelectedAvailability={availabilityData}
						/>
					)}
					{activity.tour && isActivityValid(activity.tour.activityId) && (
						<TourActivity
							activity={activity.tour}
							preSelectedActivity={selectedActivity}
							preSelectedAvailability={availabilityData}
						/>
					)}
					{activity.transfer && isActivityValid(activity.transfer.activityId) && (
						<TransferActivity
							activity={activity.transfer}
							preSelectedActivity={selectedActivity}
							preSelectedAvailability={availabilityData}
						/>
					)}
				</>
			) : (
				<div>Nenhuma atividade encontrada</div>
			)}
		</div>
	);
}

export default withRouter(EntertainmentDetails);
