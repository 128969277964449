import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import * as S from "./styles";

function CarouselComponent({ buscaAtual, type, images = [], legend }) {
	return (
		<S.Wrapper>
			<Carousel
				infiniteLoop={true}
				showThumbs={false}
				width={'100%'}
				fixedWidth
				autoPlay={images.length > 1}
				interval={3000}
				stopOnHover={true}
				statusFormatter={(current, total) => `${current} de ${total}`}
				showStatus={images.length > 1 ? true : false}
				{...(type ? 'centerMode' : '')}
			>
				{images.map((image, index) => {
					return (
						<div key={index}>
							<img
								className={`image ${type ? type : ''}`}
								src={image}
								alt={
									buscaAtual === 'Entretenimento' ? 'foto do passeio' : 'foto do quarto'
								}
							/>
							{legend ? <p className='legend'>{legend}</p> : null}
						</div>
					);
				})}
			</Carousel>
		</S.Wrapper>
	);
}

export default CarouselComponent;
