import { withStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";

import React from "react";
import ReactInputMask from "react-input-mask";

// import * as S from './styles';

const CssTextField = withStyles((theme) => ({
	root: {
		'& label.Mui-focused': {
			color: theme.palette.primary.main,
		},
		'& label': {
			color: '#78849E',
			opacity: 0.87,
		},

		'& div fieldset': {
			borderColor: '#E8E8E8',
			borderWidth: 1,
		},
		'& .MuiOutlinedInput-root': {
			boxShadow: '0px 4px 16px #455B6314',
			'&:hover fieldset': {
				borderColor: theme.palette.primary.light,
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.primary.main,
				borderWidth: 1,
			},
		},
	},
	// input: {
	// 	borderColor: "#E8E8E8",
	// 	borderWidth: 1,
	// },
}))(TextField);

function FormInput(props) {
	const { label, mask = "", error, helperText, maxLength = 300 } = props;

	return mask !== "" ? (
		<ReactInputMask mask={mask} {...props}>
			{(inputProps) => (
				<CssTextField
					label={label}
					InputLabelProps={{
						shrink: true,
					}}
					{...inputProps}
					fullWidth
					size="small"
					variant="outlined"
					error={error}
					helperText={helperText}
					inputProps={{ maxLength: maxLength }}
				/>
			)}
		</ReactInputMask>
	) : (
		<CssTextField
			label={label}
			InputLabelProps={{
				shrink: true,
			}}
			{...props}
			fullWidth
			size="small"
			variant="outlined"
			error={error}
			helperText={helperText}
			inputProps={{ maxLength: maxLength }}
		/>
	);
}

export default FormInput;
