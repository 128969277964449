import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Calendar from "../../../assets/icons/Calendar.svg";

import * as S from "./styles";
import {
  Container,
  makeStyles,
  TextField,
  // Checkbox,
  // FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import InputDateRange from "../../InputDateRange";
import { Autocomplete } from "@material-ui/lab";

import Grid from "@material-ui/core/Grid";

import ThemeButton from "../../ThemeButton";
import { useState } from "react";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import AirportsRepository from "../../../repositories/airportsRepository";
import { useGlobal } from "../../../contexts/globalContext";
import IconedInput from "../../IconedInput";

import { getSessionStorage } from "../../../utils/getSessionStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "white",
    },
    opacity: 1,
    "& .MuiInputBase-input": {
      color: `#FFF`,
    },
    "& label.Mui-focused": {
      color: `#FFF`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `#FFF`,
      color: `#FFF`,
    },
    "& .MuiFormLabel-root": {
      color: "#FFF",
      opacity: 0.45,
    },
    "& .MuiSelect-icon": {
      color: "#FFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      "&:hover fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      "&.Mui-error fieldset": {
        borderColor: "#FFF",
      },
    },
  },
}));

const CarSearchInputs = () => {
  let history = useHistory();
  const { showAlert, user } = useGlobal();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const airportsRepository = new AirportsRepository();

  const [options, setOptions] = useState([]);
  const [optionsRetirada, setOptionsRetirada] = useState([]);
  const [optionsDevolucao, setOptionsDevolucao] = useState([]);
  const [send, setSend] = useState(false);
  const [loadingRetirada, setLoadingRetirada] = useState(false);
  const [loadingDevolucao, setLoadingDevolucao] = useState(false);

  const classes = useStyles();

  const initialState = {
    cidadeRetirada: "",
    localRetirada: "",
    horaRetirada: "10:00",
    cidadeDevolucao: "",
    localDevolucao: "",
    horaDevolucao: "10:00",
    datas: "",
    mesmoLocal: true,
  };

  const changeOptions = async (value) => {
    if (value.length >= 3) {
      const res = await airportsRepository.getAirportCars(value);

      setOptions(res.data || []);

      // airportsRepository.getAirports(value).then((snapshot) => {
      //   const res = snapshot.docs.map((i) => i.data());
      //   setOptions(res || []);
      // });
    }
  };

  const SelectHours = () => {
    return hoursArray.map((count, index) => (
      <MenuItem key={index} value={count}>
        {count}
      </MenuItem>
    ));
  };

  const [state, setState] = useState(initialState);

  const handleSubmit = () => {
    setSend(true);
    setSend(false);
    cleanStorageBeforeSearch();
    sessionStorage.setItem("carSearch", JSON.stringify(state));
    sessionStorage.setItem("buscaAtual", "Carro");
    if (
      state.cidadeRetirada === "" ||
      state.localRetirada === "" ||
      state.datas === "" ||
      state.horaDevolucao === "" ||
      state.horaRetirada === ""
    ) {
      showAlert("error", "Por favor, preencha todos os campos");
    } else if (!state.mesmoLocal) {
      if (state.localDevolucao === "" || state.cidadeDevolucao === "") {
        showAlert("error", "Por favor, preencha todos os campos");
      } else {
        history.push("/busca/resultado-pesquisa");
      }
    } else {
      history.push("/busca/resultado-pesquisa");
    }
  };

  const getLocationData = async (iata, country) => {
    const res = await airportsRepository.getRentalLocation(iata, country);

    return res;
  };

  useEffect(() => {
    const callLocations = async () => {
      if (state.cidadeRetirada) {
        setLoadingRetirada(true);

        const res = await getLocationData(
          state.cidadeRetirada.iata,
          state.cidadeRetirada.countryIso
        );
        if (res.data) {
          let filteredLocations = res.data;
          // if (state.cidadeRetirada.countryIso === "BR") {
          // 	filteredLocations = res.data.filter((location) => location.rental.name.startsWith("LOCALIZA"));
          // }
          setOptionsRetirada(filteredLocations);
          setLoadingRetirada(false);
        }
      }
    };
    callLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cidadeRetirada]);

  useEffect(() => {
    const callLocations = async () => {
      if (state.cidadeDevolucao) {
        setLoadingDevolucao(true);

        const res = getLocationData(
          state.cidadeDevolucao.iata,
          state.cidadeDevolucao.countryIso
        );

        if (res.data) {
          setOptionsDevolucao(res.data);
          setLoadingDevolucao(false);
        }
      }
    };

    callLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cidadeDevolucao]);

  useEffect(() => {
    const car = getSessionStorage("carSearch");
    if (car) setState(car);
  }, []);

  return (
    <S.Wrapper>
      <Container>
        <div className="origem-destino-control">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={state.mesmoLocal ? 6 : 3}>
              <Autocomplete
                freeSolo
                disableClearable
                className={classes.root}
                noOptionsText="Não encontrado"
                options={options}
                getOptionLabel={(option) =>
                  option ? `${option.description}` : ""
                }
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    changeOptions(value);
                  }
                }}
                value={state.cidadeRetirada !== "" ? state.cidadeRetirada : ""}
                onChange={(event, newValue) => {
                  setOptionsRetirada([]);
                  setState({
                    ...state,
                    cidadeRetirada: newValue,
                    localRetirada: "",
                  });
                }}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Cidade Retirada"
                    variant="outlined"
                    fullWidth
                    className={classes.root}
                    error={
                      send &&
                      state.cidadeRetirada === initialState.cidadeRetirada
                    }
                    helperText={
                      send &&
                      state.cidadeRetirada === initialState.cidadeRetirada
                        ? "Cidade de retirada não pode ser vazio!"
                        : " "
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={state.mesmoLocal ? 6 : 3}>
              <Autocomplete
                freeSolo
                loading={loadingRetirada}
                disableClearable
                className={classes.root}
                noOptionsText="Não encontrado"
                options={optionsRetirada ? optionsRetirada : []}
                getOptionLabel={(option) => {
                  if (!option) return "";

                  return `${option.address.address} - ${option.address.cityName}`;
                }}
                onChange={(event, newValue) => {
                  setState({ ...state, localRetirada: newValue });
                }}
                value={state.localRetirada !== "" ? state.localRetirada : ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Local Retirada"
                    variant="outlined"
                    fullWidth
                    className={classes.root}
                    error={
                      send && state.localRetirada === initialState.localRetirada
                    }
                    helperText={
                      send && state.localRetirada === initialState.localRetirada
                        ? "Local retirada não pode ser vazio!"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            {!state.mesmoLocal && (
              <>
                <Grid className="search-divider" item xs={12} sm={6} md={3}>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    className={classes.root}
                    noOptionsText="Não encontrado"
                    options={options}
                    getOptionLabel={(option) =>
                      option ? `${option.description}` : ""
                    }
                    onInputChange={(event, value, reason) => {
                      if (reason === "input") {
                        changeOptions(value);
                      }
                    }}
                    onChange={(event, newValue) => {
                      setState({
                        ...state,
                        cidadeDevolucao: newValue,
                        localDevolucao: "",
                      });
                    }}
                    value={
                      state.cidadeDevolucao !== "" ? state.cidadeDevolucao : ""
                    }
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Cidade Devolução"
                        variant="outlined"
                        fullWidth
                        className={classes.root}
                        error={
                          send &&
                          state.cidadeDevolucao === initialState.cidadeDevolucao
                        }
                        helperText={
                          send &&
                          state.cidadeDevolucao === initialState.cidadeDevolucao
                            ? "Cidade devolução não pode ser vazio!"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    freeSolo
                    loading={loadingDevolucao}
                    disableClearable
                    className={classes.root}
                    noOptionsText="Não encontrado"
                    options={optionsDevolucao ? optionsDevolucao : []}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.rental.name} - ${option.address.address} `
                        : ""
                    }
                    onChange={(event, newValue) => {
                      setState({ ...state, localDevolucao: newValue });
                    }}
                    value={
                      state.localDevolucao !== "" ? state.localDevolucao : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Local Devolução"
                        variant="outlined"
                        fullWidth
                        className={classes.root}
                        error={
                          send &&
                          state.localDevolucao === initialState.localDevolucao
                        }
                        helperText={
                          send &&
                          state.localDevolucao === initialState.localDevolucao
                            ? "Local Devolução não pode ser vazio!"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={3}>
              <IconedInput
                value={state.horaRetirada}
                select
                label="Hora Retirada"
                width={150}
                onChange={(e) =>
                  setState({ ...state, horaRetirada: e.target.value })
                }
              >
                {SelectHours()}
              </IconedInput>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <IconedInput
                value={state.horaDevolucao}
                select
                label="Hora Devolução"
                width={150}
                onChange={(e) =>
                  setState({ ...state, horaDevolucao: e.target.value })
                }
              >
                {SelectHours()}
              </IconedInput>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputDateRange
                value={state.datas.abreviada && state.datas.abreviada}
                icon={Calendar}
                label="Data ida/volta"
                width={190}
                onChange={(e) => setState({ ...state, datas: e })}
                error={send && state.datas === initialState.datas}
                helperText={
                  send && state.datas === initialState.datas
                    ? "Data não pode ser vazia"
                    : ""
                }
                minStartDays={
                  user.campaign.advanceDaysCar
                    ? user.campaign.advanceDaysCar
                    : 7
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ThemeButton onClick={handleSubmit} className="submit1">
                Buscar
              </ThemeButton>
            </Grid>
          </Grid>
        </div>
        {/* <div className='devolver-mesmo-local'>
          <FormControlLabel
            control={
              <Checkbox
                checked={!state.mesmoLocal}
                onChange={() =>
                  setState({
                    ...state,
                    mesmoLocal: !state.mesmoLocal,
                  })
                }
              />
            }
            label='Devolver em um lugar diferente?'
          />
        </div> */}
      </Container>
    </S.Wrapper>
  );
};

export default CarSearchInputs;

const hoursArray = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "23:59",
];
