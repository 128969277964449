import * as S from "./styles";

import { useEffect, useState } from "react";

import ReprocessarPagamento from "../../ReprocessarPagamento";
import BookingsRepository from "../../../repositories/bookingsRepository";
import { useGlobal } from "../../../contexts/globalContext";
import PlaceIcon from "@material-ui/icons/Place";
import Loader from "../../Loader";
import { useHistory } from "react-router-dom";
import Auth2faModal from "../../Auth2FaModal";

import { ThemeButton } from "../../ThemeButton/styles";

import { format, isDate, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

const EntertainmentCard = ({
  booking = {},
  departureDate,
  arrivalDate,
  coinAlias,
}) => {
  let history = useHistory();

  const bookingsRepository = new BookingsRepository();
  const { showAlert } = useGlobal();

  const [openDialogReprocessar, setOpenDialogReprocessar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [openModal2fa, setOpenModal2fa] = useState(false);
  const [image, setImage] = useState(
    "https://via.placeholder.com/200x200.png?text=Carregando..."
  );

  const activityName =
    booking?.bookingService?.entertainment?.name ||
    booking?.description ||
    "Atividade";
  const activityLocation =
    booking?.bookingService?.entertainment?.location ||
    "Local não especificado";
  const activityDescription =
    booking?.bookingService?.entertainment?.description ||
    "Sem descrição disponível";

  useEffect(() => {
    if (booking?.bookingService?.entertainment?.image) {
      setImage(booking.bookingService.entertainment.image);
    }
  }, [booking]);

  const closeModal2fa = () => setOpenModal2fa(false);

  const onChangeOpenDialogReprocessar = (open) =>
    setOpenDialogReprocessar(open);

  const getStatusTranslate = (status) => {
    switch (status) {
      case 1:
        return "pendente";
      case 2:
        return "emitido";
      case 3:
        return "expirado";
      case 4:
        return "finalizado";
      case 5:
        return "cancelado";
      default:
        return "desconhecido";
    }
  };

  const reprocessar = () => {
    if (
      (booking.paymentTransaction === null ||
        booking.paymentTransaction === undefined) &&
      booking.cashTotalValue > 0
    ) {
      onChangeOpenDialogReprocessar(true);
    } else {
      setOpenLoader(true);
      bookingsRepository
        .reprocessarPagamento(booking.bookingCode, {})
        .then((res) => {
          setOpenLoader(false);
          sessionStorage.setItem("@bookingCompleted", JSON.stringify(res));
          switch (res.status) {
            case 200:
              history.push("/busca/finalizado");
              break;
            case 201:
              if (res.data.auth2fa) {
                if (res.data.redirectAuth2fa !== null) {
                  setOpenLoader(true);
                  window.location.replace(res.data.redirectAuth2fa);
                } else {
                  setOpenModal2fa(true);
                }
              } else {
                history.push("/busca/finalizado");
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          setOpenLoader(false);
          switch (err?.response?.status) {
            case 400:
              showAlert("Há algo de errado nos seus dados, verifique!");
              break;
            case 401:
              history.push("/session-expires");
              break;
            case 409:
              sessionStorage.setItem(
                "@bookingError",
                JSON.stringify(err.response.data.error)
              );
              sessionStorage.setItem(
                "reprocessar",
                JSON.stringify({ reprocessar: true })
              );
              history.push("/busca/booking-error");
              break;
            case 504:
              sessionStorage.setItem(
                "@bookingError",
                JSON.stringify("Tempo expirou, tente novamente!")
              );
              sessionStorage.setItem(
                "reprocessar",
                JSON.stringify({ reprocessar: true })
              );
              history.push("/busca/booking-error");
              break;
            default:
              showAlert(
                "error",
                `Compra não processada, por favor, tente novamente!`
              );
              break;
          }
        });
    }
  };

  const reenviarVoucher = (locator) => {
    bookingsRepository
      .reenviarVoucher(locator)
      .then((res) =>
        showAlert("success", "Voucher reenviado, verifique seu e-mail!")
      )
      .catch((err) => showAlert("error", "Houve algum erro, tente novamente!"));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    try {
      if (typeof dateString === "string") {
        if (isDate(parseISO(dateString))) {
          return format(parseISO(dateString), "dd/MM/yyyy", { locale: pt });
        }
      }

      const date = new Date(dateString);
      if (!isNaN(date)) {
        return format(date, "dd/MM/yyyy", { locale: pt });
      }

      return "";
    } catch (error) {
      console.error("Erro ao formatar data:", error);
      return "";
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "";

    try {
      if (typeof dateString === "string") {
        if (isDate(parseISO(dateString))) {
          return format(parseISO(dateString), "dd/MM/yyyy HH:mm", {
            locale: pt,
          });
        }
      }

      const date = new Date(dateString);
      if (!isNaN(date)) {
        return format(date, "dd/MM/yyyy HH:mm", { locale: pt });
      }

      return "";
    } catch (error) {
      console.error("Erro ao formatar data e hora:", error);
      return "";
    }
  };

  return (
    <S.BlankCard>
      <ReprocessarPagamento
        open={openDialogReprocessar}
        onClose={onChangeOpenDialogReprocessar}
        booking={booking}
      />
      <Loader openLoader={openLoader} />
      <Auth2faModal
        open={openModal2fa}
        onClose={closeModal2fa}
        booking={{ data: booking }}
        type="reprocessar"
      />
      <S.CardHeader>
        <S.Title>
          Código da reserva: {booking?.bookingCode || booking?.locator || "N/A"}
          <span className="subtitle">
            Data da reserva:{" "}
            {formatDate(booking?.bookingDateStr || booking?.bookingDate)}
          </span>
        </S.Title>
        {booking?.statusId && (
          <S.StatusContainer>
            {booking?.statusId === 1 && (
              <ThemeButton className="reprocessar" onClick={reprocessar}>
                Reprocessar Pagamento
              </ThemeButton>
            )}
            {booking?.statusId === 2 && (
              <ThemeButton
                className="reprocessar"
                onClick={() =>
                  reenviarVoucher(booking?.bookingCode || booking?.locator)
                }
              >
                Reenviar Voucher
              </ThemeButton>
            )}

            <S.Status
              className={`status ${getStatusTranslate(booking.statusId)}`}
            >
              {getStatusTranslate(booking.statusId)}
            </S.Status>
          </S.StatusContainer>
        )}
      </S.CardHeader>
      <S.Subtitle>Detalhes da atividade</S.Subtitle>

      <S.FlightSummaryCardDetails>
        <div>
          {image && (
            <img
              src={image}
              alt={`Imagem de ${activityName}`}
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <S.HotelDetails item xs={12}>
          <div className="content">
            <div className="hotel-details">
              <span className="hotel-name">{activityName}</span>
            </div>
          </div>
          <div className="roomDetails">
            {activityDescription && (
              <span>
                <b>Descrição:</b> {activityDescription}
              </span>
            )}
            <br />

            <span style={{ color: "#ff5757" }}>
              <b>Política de cancelamento:</b> Para maiores detalhes, consulte o
              voucher encaminhado ao seu e-mail.
            </span>
          </div>
        </S.HotelDetails>
      </S.FlightSummaryCardDetails>

      <S.BottomInfos>
        {booking.passengers && booking.passengers.length > 0 && (
          <S.Travellers>
            <S.Subtitle>VIAJANTES</S.Subtitle>
            {booking.passengers.map((passenger, index) => (
              <S.TravellerName key={index}>
                {`${passenger.name || "Nome"}`}
              </S.TravellerName>
            ))}
          </S.Travellers>
        )}

        {booking.pointsTotalValue ? (
          <S.ValorTotal>
            Valor em {coinAlias || "pontos"}: {booking.pointsTotalValue}
          </S.ValorTotal>
        ) : null}

        {booking.cashTotalValue ? (
          <S.ValorTotal>
            Valor em R$: {booking.cashTotalValue.toLocaleString("pt-BR")}
          </S.ValorTotal>
        ) : null}
      </S.BottomInfos>

      <S.ExpirationDate>
        <span>
          {booking?.bookingService?.expirationDate && (
            <span>
              <b>Esta reserva expira em: </b>{" "}
              {formatDateTime(booking.bookingService.expirationDate)}
            </span>
          )}
        </span>
      </S.ExpirationDate>
    </S.BlankCard>
  );
};

export default EntertainmentCard;
