import styled, { css } from "styled-components";
import { rgba } from "polished";
import { getBrandStorage } from "../../../../utils/getBrand";
import { isDiferentiatedBrands } from "../../../../themes/themes";
import { Typography } from "@material-ui/core/";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const brand = getBrandStorage();

export const Box = styled.div`
	${({ theme }) => css`
    position: absolute;
    width: 600px;
    max-height: 850px;
    font-family: "Poppins";
    border: 1px solid ${rgba('#707070', 0.17)};
    border-radius: 5px !important;
    background-color: #ffffff;
    overflow: hidden;
    left: -300px;
    z-index: 10;
    box-shadow: 0px 1px 4px #707070;
    
    padding-bottom:15px;

    .searchTitle {
      color: ${theme.palette.primary.main}; 
      font-size: 16px;
      font-weight: 700;
      height: 22px;
      letter-spacing: 0.15px;
      line-height: 22px;
      position: relative;
      white-space: nowrap;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 30px;
    }

    .foundCities {
      color: #6f6f6f;
      font-size: 16px;
      font-weight: 400;
      height: 19px;
      letter-spacing: 0.15px;
      line-height: normal;
      position: relative;
      white-space: nowrap;
      padding-left: 24px;
      padding-bottom: 30px;
      cursor: pointer;
      
      .rightArrow {
        fill: ${theme.palette.primary.main} !important;
      }
    }   
    
    .card {
      position: relative;
      display: flex;
      width: 100%;
      height: 120px;
      border: 0.5px solid ${rgba('#d9d9d9', 0.17)};
      background-color: #F2F2F2;
      box-shadow: unset;
      cursor: pointer;
      
      .cardMedia {
        height: 90px;
        width: 140px;
        object-fit: cover;
        position: absolute;
        left: 24px;
        top: 10px;
        border-radius: 10px;

        .cardImage {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
  
      .cardContent {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .cardTitle {
          color: #6f6f6f;
          font-size: 16px;
          font-weight: 700;
          height: 44px;
          left: 186px;
          letter-spacing: 0.15px;
          line-height: 22px;
          position: absolute;
          top: 10px;
        }

        .cardCity {
          color: #6f6f6f;
          font-size: 14px;
          font-weight: 400;
          height: 22px;
          left: 186px;
          letter-spacing: 0.15px;
          line-height: 22px;
          position: absolute;
          top: 58px;
          white-space: nowrap;
        }
        
        .cardPrice {
          color: ${theme.palette.primary.main};
          font-family: "Roboto-Bold", Helvetica;
          font-size: 16px;
          font-weight: 700;
          height: 22px;
          left: 186px;
          letter-spacing: 0.15px;
          line-height: 22px;
          position: absolute;
          top: 84px;
          white-space: nowrap;

          .text {
            color: #7f7f7f;
            font-size: 12px;
            font-weight: 500;
            height: 15px;
          }
        }
      }
    }

    p {
      cursor: pointer;
      position: relative;
      top: 10px;
      left: 14px;
      font-weight: 700;
      font-size: 15px;
      height: 60px;
      color: #6f6f6f;
      letter-spacing: 0.15px;
      line-height: 22px;
      padding-right: 20px;
    }

    .entretenimento-control {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 140px;
      flex-direction: column;

      ${theme.breakpoints.up('md')} {
        .search-divider {
          border-left 1px solid ${rgba(
											isDiferentiatedBrands(brand)
												? theme.palette.primary.main
												: theme.palette.secondary.main,
											0.4
										)}
        }
      }

      .MuiGrid-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
    }

    
    .allResults {
      cursor: pointer;
      position: relative;
      top: 10px;
      left: 14px;
      font-weight: 700;
      font-size: 15px;
      height: 40px;
      color: #6f6f6f;
      letter-spacing: 0.15px;
      line-height: 22px;
    }

    .submit {
      display: flex;
      justify-content: center;
    }

    .submit1 {
      width: 140px !important;
      height: 55px !important;
    }

    .allResultsIcon {
      top: 4px;
      left: 504px;
    }
  `}
`;
export const CustomTypography = styled(Typography)`
    ${({ theme }) => css`
        text-align: center;
        color: ${theme.palette.primary.main};
    `}
`;

export const Title = styled.div`
    ${({ theme }) => css`
        text-align: left;
        font: normal normal 10px Poppins;
        letter-spacing: 0px;
        color: ${theme.palette.primary.main};
        opacity: 1;
    `}
`;

export const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
    ${({ theme }) => css`
        height: 30px !important;
        width: 30px !important;
        position: absolute;
        left: 516px;
        top: 76px;
        fill: ${theme.palette.primary.main} !important;
    `}
`;

export const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
`;
