import React, { createContext, useState, useEffect, useContext, useCallback } from "react";

import UserRepository from "../repositories/userRepository";
import { auth } from "../services/firebase";
import { getBrand, getBrandStorage } from "../utils/getBrand";
import { getImagesStorage } from "../utils/getCampaignColors";
import api from "../services/api";

import { getSessionStorage } from "../utils/getSessionStorage";

const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const [signed, setSigned] = useState(false);
	const [brand, setBrand] = useState("padrao");
	const [globalLoader, setGlobalLoader] = useState(false);

	const [loadingPointsBalance, setLoadingPointsBalance] = useState(false);

	const [state, setState] = useState({
		alert: {
			type: "info",
			message: "",
			open: false,
		},
		loading: false,
	});

	useEffect(() => {
		const storagedUser = getSessionStorage("@App:user");
		// const storagedToken = getSessionStorage('@App:token');

		const brd = getBrandStorage();
		changeBrand(brd);
		if (storagedUser) {
			setUser(storagedUser);
			setSigned(true);
		}
	}, []);

	useEffect(() => {
		if (process.env.REACT_APP_CAMPAIGN_CODE) {
			sessionStorage.setItem("campaign", process.env.REACT_APP_CAMPAIGN_CODE);
		}
	}, []);

	const logout = () => {
		setUser(null);
		setSigned(false);

		sessionStorage.removeItem("@App:user");
		sessionStorage.removeItem("App:token");
		sessionStorage.removeItem("auth");
		sessionStorage.removeItem("campaign");
		sessionStorage.removeItem("@App:imagesStorage");
		sessionStorage.removeItem("@App:campaignConfig");
	};

	const changeBrand = (brand) => {
		setBrand(brand);
	};

	const login = async () => {
		const campaignIdFromEnv = process.env.REACT_APP_CAMPAIGN_ID;

		const userRepository = new UserRepository();
		const alreadyLoggedUser = getSessionStorage("@App:user");
		const voucherUser = alreadyLoggedUser?.travelVoucher ? alreadyLoggedUser?.uid : null;
		let result = await userRepository
			.getUserData(voucherUser)
			.then(async (res) => {
				if (res.status === 401) {
					return {
						success: false,
						error: "Tivemos um problema com sua autenticação, entre em contato com o consultor.",
					};
				}

				if (campaignIdFromEnv && res.data?.campaign?.id?.toString() !== campaignIdFromEnv?.toString()) {
					return {
						success: false,
						error: "Tivemos um problema para efetuar a autenticação da campanha, por favor tente novamente mais tarde.",
					};
				}

				if (res?.data?.usedTravelVoucher) {
					return {
						success: false,
						error: "Esse voucher já foi utilizado, favor efetuar o login com sua conta pessoal.",
					};
				}

				setGlobalLoader(true);

				setUser(res.data);
				setSigned(true);
				sessionStorage.setItem("@App:user", JSON.stringify(res.data));

				let campaignConfig = res?.data?.campaign?.campaignConfigLayout;
				sessionStorage.setItem("@App:campaignConfig", JSON.stringify(campaignConfig));

				//recupera imagens da campanha e salva no storage
				const imgs = await getImagesStorage(res?.data?.campaign?.id);
				sessionStorage.setItem("@App:imagesStorage", JSON.stringify(imgs));

				const brd = getBrand(res?.data?.campaign?.campaignCode?.toString());
				changeBrand(brd);

				sessionStorage.setItem("campaign", res.data.campaign.campaignCode.toString());

				setGlobalLoader(false);
				return { success: true };
			})
			.catch((err) => {
				return {
					success: false,
					error: "Tivemos um problema, por favor tente novamente mais tarde.",
				};
			});

		if (!result?.success) {
			sessionStorage.removeItem("auth");
			sessionStorage.removeItem("@App:user");
			setSigned(false);
			logout();
			//window.location.href = "/session-expires";
		}

		return {
			success: result?.success,
			error: result?.error,
			returnedObj: result?.returnedObj,
		};
	};

	const loginFirebase = async (user, pass) => {
		return auth
			.signInWithEmailAndPassword(user, pass)
			.then(async (userCredential) => {
				const usrTemp = JSON.parse(JSON.stringify(userCredential.user));
				if (!usrTemp?.stsTokenManager) return { success: false, error: "Usuário ou senha inválidos." };

				sessionStorage.setItem("auth", JSON.stringify(usrTemp.stsTokenManager));
				let result = await login(usrTemp);
				if (result?.success) {
					return { success: true, returnedObj: usrTemp };
				} else {
					sessionStorage.removeItem("auth");
					sessionStorage.removeItem("@App:user");
					return {
						success: result?.success,
						error: result?.error,
						returnedObj: result?.returnedObj,
					};
				}
			})
			.catch((error) => {
				return { success: false, error: "Usuário ou senha inválidos." };
			});
	};

	const loginGuestByCampaignCode = async (code) => {
		setGlobalLoader(true);
		return api
			.get(`/api/login-guest-by-campaign?code=${code}`)
			.then(async (response) => {
				const usrTemp = JSON.parse(JSON.stringify(response.data));
				if (!usrTemp?.idToken) return { success: false, error: "Ocorreu um erro na autenticação." };

				sessionStorage.setItem(
					"auth",
					JSON.stringify({
						access_token: usrTemp.idToken,
						apiKey: usrTemp.localId,
						expirationTime: usrTemp.expiresIn,
						refreshToken: "",
					})
				);
				let result = await login(usrTemp);

				if (result?.success) {
					return { success: true, returnedObj: usrTemp };
				} else {
					sessionStorage.removeItem("auth");
					sessionStorage.removeItem("@App:user");
					return {
						success: result?.success,
						error: result?.error,
						returnedObj: result?.returnedObj,
					};
				}
			})
			.catch((error) => {
				logout();
				// console.log("error", error);
			});
	};

	const closeAlert = useCallback(() => {
		setState((oldState) => ({
			...oldState,
			alert: {
				type: oldState.alert.type,
				message: "",
				open: false,
			},
		}));
	}, [setState]);

	const showAlert = (type, message, duration) => {
		setState((oldState) => ({
			...oldState,
			alert: {
				type: type,
				message: message,
				open: true,
				duration: duration,
			},
		}));
	};

	const setLoading = (loading) => {
		setState((oldState) => ({
			...oldState,
			loading: loading,
		}));
	};

	const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

	return (
		<GlobalContext.Provider
			value={{
				state,
				signed,
				user,
				loadingPointsBalance,
				brand,
				changeBrand,
				loginFirebase,
				loginGuestByCampaignCode,
				login,
				setUser,
				logout,
				showAlert,
				setLoading,
				sleep,
				globalLoader,
				closeAlert,
				setLoadingPointsBalance,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobal = () => {
	const context = useContext(GlobalContext);

	return context;
};

export default GlobalContext;
