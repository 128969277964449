import React, { useState } from "react";

import {
  Checkbox,
  MenuItem,
  FormControlLabel,
  TextField,
  InputAdornment,
  makeStyles,
  Box,
  Popper,
} from "@material-ui/core/";

import LocalAirportIcon from "@material-ui/icons/LocalAirport";

import Landing from "../../../assets/icons/landing.svg";
import TakeOff from "../../../assets/icons/takeoff.svg";
import Calendar from "../../../assets/icons/Calendar.svg";

import * as S from "./styles";
import { Container } from "@material-ui/core";
import IconedInput from "../../IconedInput";
import InputDateRange from "../../InputDateRange";
import Grid from "@material-ui/core/Grid";
import { counter } from "../../../constants/constants";
import ThemeButton from "../../ThemeButton";
import { useEffect } from "react";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import { Autocomplete } from "@material-ui/lab";
import AirportsRepository from "../../../repositories/airportsRepository";
import InputCalendar from "../../InputDate";
import { useGlobal } from "../../../contexts/globalContext";
import { useHistory } from "react-router-dom";

import { getSessionStorage } from "../../../utils/getSessionStorage";

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "white",
    },
    opacity: 1,
    "& .MuiInputBase-input": {
      color: `#FFF`,
    },
    "& label.Mui-focused": {
      color: `#FFF`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `#FFF`,
      color: `#FFF`,
    },
    "& .MuiFormLabel-root": {
      color: "#FFF",
      opacity: 0.45,
    },
    "& .MuiSelect-icon": {
      color: "#FFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      "&:hover fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      "&.Mui-error fieldset": {
        borderColor: "#FFF",
      },
    },
  },
}));

const FlySearchInputs = () => {
  const { showAlert /** , signed **/, user } = useGlobal();
  let history = useHistory();

  const classes = useStyles();
  const airportsRepository = new AirportsRepository();

  const initialState = {
    origem: null,
    destino: null,
    datas: "",
    adultos: 1,
    criancas: 0,
    bebes: 0,
    idavolta: true,
    tarifasBagagem: false,
    apenasVoosDiretos: false,
  };

  const [state, setState] = useState(initialState);
  const [send, setSend] = useState(false);
  const [optionsDepart, setOptionsDepart] = useState([]);
  const [optionsArrival, setOptionsArrival] = useState([]);

  const numberOfPeople = () => {
    return counter.map((count, index) => (
      <MenuItem key={index} value={count}>
        {count}
      </MenuItem>
    ));
  };

  const changeOptionsDeparture = async (value) => {
    if (value.length >= 3) {
      const res = await airportsRepository.getAirports(value);

      setOptionsDepart(res.data || []);
    }
  };

  const changeOptionsArrival = async (value) => {
    if (value.length >= 3) {
      const res = await airportsRepository.getAirports(value);
      setOptionsArrival(res.data || []);
    }
  };

  const handleSubmit = () => {
    // if (signed) {
    setSend(true);

    const totalPassengers = state.adultos + state.criancas + state.bebes;
    if (totalPassengers > 9) {
      showAlert(
        "warning",
        "Ops! Para grupos acima de 9 pessoas, nosso time de especialistas entra em ação! Entre em contato e descubra opções personalizadas para sua viagem."
      );
      history.push("/plantrip");
      return;
    }

    if (
      state.origem === null ||
      state.destino === null ||
      state.datas === "" ||
      (state.adultos === 0 && state.criancas === 0 && state.bebes === 0)
    ) {
      showAlert("error", "Por favor, preencha todos os campos");
    } else {
      cleanStorageBeforeSearch();
      sessionStorage.setItem("flySearch", JSON.stringify(state));
      sessionStorage.setItem("buscaAtual", "Aéreo");
      history.push("/busca/resultado-pesquisa");
    }
    // } else {
    //   showAlert('error', 'Efetue o login para poder buscar passagens');
    // }
  };

  // useEffect(() => {
  //   if (state !== initialState) {
  //     sessionStorage.setItem('flySearch', JSON.stringify(state));
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state]);

  useEffect(() => {
    if (getSessionStorage("flySearch"))
      setState(getSessionStorage("flySearch"));
  }, []);

  return (
    <S.Wrapper>
      <Container>
        <div className="idavolta-control">
          <ThemeButton
            className={`go-and-back ${state.idavolta && "active"}`}
            onClick={() => setState({ ...state, idavolta: true })}
          >
            Ida e volta
          </ThemeButton>
          <ThemeButton
            className={`go-and-back ${!state.idavolta && "active"}`}
            onClick={() => setState({ ...state, idavolta: false })}
          >
            Somente ida
          </ThemeButton>
        </div>
        <div className="origem-destino-control">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2}>
              <Autocomplete
                PopperComponent={PopperMy}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocalAirportIcon}
                            sx={{
                              bgColor: (theme) => theme.palette.secondary.main,
                              mr: 3,
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <span>{`${props.description}`}</span>
                          <S.CustomTypography variant="body2">
                            <b>{props.iata}</b>
                          </S.CustomTypography>{" "}
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                freeSolo
                // error={state.origem === {}}
                // helperText={state.origem === {} ? "Origem não pode ser vazio!" : " "}
                className={classes.root}
                noOptionsText="Não encontrado"
                options={optionsDepart}
                getOptionLabel={(option) => `${option.description}`}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    changeOptionsDeparture(value);
                  }
                }}
                onChange={(event, newValue) => {
                  setState({ ...state, origem: newValue });
                }}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Origem"
                    variant="outlined"
                    fullWidth
                    className={classes.root}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="icon" src={TakeOff} />
                        </InputAdornment>
                      ),
                    }}
                    error={send && state.origem === initialState.origem}
                    helperText={
                      send && state.origem === initialState.origem
                        ? "Origem não pode ser vazio"
                        : ""
                    }
                  />
                )}
                value={state.origem}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Autocomplete
                PopperComponent={PopperMy}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocalAirportIcon}
                            sx={{
                              bgColor: (theme) => theme.palette.secondary.main,
                              mr: 3,
                            }}
                          />
                        </Grid>

                        <Grid item xs>
                          <span>{`${props.description}`}</span>
                          <S.CustomTypography variant="body2">
                            <b>{props.iata}</b>
                          </S.CustomTypography>{" "}
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                freeSolo
                className={classes.root}
                noOptionsText="Não encontrado"
                options={optionsArrival}
                getOptionLabel={(option) => `${option.description}`}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    changeOptionsArrival(value);
                  }
                }}
                onChange={(event, newValue) => {
                  setState({ ...state, destino: newValue });
                }}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Destino"
                    variant="outlined"
                    fullWidth
                    className={classes.root}
                    error={send && state.destino === initialState.destino}
                    helperText={
                      send && state.destino === initialState.destino
                        ? "Destino não pode ser vazio"
                        : ""
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="icon" src={Landing} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                value={state.destino}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              {state.idavolta ? (
                <InputDateRange
                  icon={Calendar}
                  label="Data ida/volta"
                  width={190}
                  value={state.datas.abreviada}
                  onChange={(e) => setState({ ...state, datas: e })}
                  error={send && state.datas === initialState.datas}
                  helperText={
                    send && state.datas === initialState.datas
                      ? "Data não pode ser vazia"
                      : ""
                  }
                  minStartDays={
                    user.campaign.advanceDaysCar
                      ? user.campaign.advanceDaysCar
                      : 7
                  }
                />
              ) : (
                <InputCalendar
                  icon={Calendar}
                  label="Data do voo"
                  width={190}
                  value={state.datas.abreviada}
                  onChange={(e) => setState({ ...state, datas: e })}
                  error={send && state.datas === initialState.datas}
                  helperText={
                    send && state.datas === initialState.datas
                      ? "Data não pode ser vazia"
                      : ""
                  }
                  minStartDays={7}
                />
              )}
            </Grid>
            <Grid className="search-divider" item xs={12} sm={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <IconedInput
                    value={state.adultos}
                    select
                    label="Adultos"
                    width={150}
                    onChange={(e) =>
                      setState({ ...state, adultos: e.target.value })
                    }
                    error={
                      send &&
                      state.adultos === 0 &&
                      state.criancas === 0 &&
                      state.bebes === 0
                    }
                    helperText={
                      send &&
                      state.adultos === 0 &&
                      state.criancas === 0 &&
                      state.bebes === 0
                        ? "Selecione pelo menos um tipo de passageiro"
                        : ""
                    }
                  >
                    {numberOfPeople()}
                  </IconedInput>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <p className="label-input-info">(2 à 11 anos)</p>
                  <IconedInput
                    value={state.criancas}
                    select
                    label="Crianças"
                    width={150}
                    onChange={(e) =>
                      setState({ ...state, criancas: e.target.value })
                    }
                  >
                    {numberOfPeople()}
                  </IconedInput>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <IconedInput
                    value={state.bebes}
                    select
                    label="Bebês"
                    width={150}
                    onChange={(e) =>
                      setState({ ...state, bebes: e.target.value })
                    }
                  >
                    {numberOfPeople()}
                  </IconedInput>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <ThemeButton className="submit1" onClick={handleSubmit}>
                    Buscar
                  </ThemeButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="tarifas-voos-diretos">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.tarifasBagagem}
                onChange={() =>
                  setState({ ...state, tarifasBagagem: !state.tarifasBagagem })
                }
              />
            }
            label="Tarifas com despacho de bagagem"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.apenasVoosDiretos}
                onChange={() =>
                  setState({
                    ...state,
                    apenasVoosDiretos: !state.apenasVoosDiretos,
                  })
                }
              />
            }
            label="Apenas voos diretos"
          />
        </div>
      </Container>
    </S.Wrapper>
  );
};

export default FlySearchInputs;
