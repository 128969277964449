import React, { useEffect } from "react";

import { MenuItem, TextField } from "@material-ui/core/";

import Calendar from "../../../assets/icons/Calendar.svg";

import * as S from "./styles";
import { Container, makeStyles } from "@material-ui/core";
import IconedInput from "../../IconedInput";
import InputDateRange from "../../InputDateRange";
import Grid from "@material-ui/core/Grid";
import { counter, counter2 } from "../../../constants/constants";
import ThemeButton from "../../ThemeButton";
import { useState } from "react";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import CitiesRepository from "../../../repositories/citiesRepository";
import { Autocomplete } from "@material-ui/lab";
import { useGlobal } from "../../../contexts/globalContext";
import { useHistory } from "react-router-dom";
import ChooseChidsAge from "../../ChooseChidsAge";

import { getSessionStorage } from "../../../utils/getSessionStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "white",
    },
    opacity: 1,
    "& .MuiInputBase-input": {
      color: `#FFF`,
    },
    "& label.Mui-focused": {
      color: `#FFF`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `#FFF`,
      color: `#FFF`,
    },
    "& .MuiFormLabel-root": {
      color: "#FFF",
      opacity: 0.45,
    },
    "& .MuiSelect-icon": {
      color: "#FFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      "&:hover fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      "&.Mui-error fieldset": {
        borderColor: "#FFF",
      },
    },
  },
}));
const HotelSearchInputs = () => {
  const { showAlert /**, signed **/, user } = useGlobal();
  let history = useHistory();
  const classes = useStyles();
  const citiesRepository = new CitiesRepository();

  const initialState = {
    cidade: "",
    datas: "",
    qtAdult: 1,
    qtChild: 0,
    qtRooms: 1,
  };
  const [state, setState] = useState(initialState);
  const [options, setOptions] = useState([]);
  const [send, setSend] = useState(false);
  const [openModalChild, setOpenModalChild] = useState(false);

  useEffect(() => {}, [state]);

  const numberOfPeople = () => {
    return counter.map((count, index) => (
      <MenuItem key={index} value={count}>
        {count}
      </MenuItem>
    ));
  };

  const numberOfRooms = () => {
    return counter2.map((count, index) => (
      <MenuItem key={index} value={count}>
        {count}
      </MenuItem>
    ));
  };

  const changeOptions = (value) => {
    if (value.length >= 3) {
      // citiesRepository.getCities(value).then((snapshot) => {
      //   const res = snapshot.docs.map((i) => i.data());

      //   setOptions(res || []);
      // });

      citiesRepository.getHotelCities(value).then((res) => {
        let cities = [];
        res?.data?.map((i) =>
          i?.destinations.map((d) =>
            cities.push({ ...d, country: i.country, isoCode: i.isoCode })
          )
        );
        setOptions(cities);
      });
    }
  };

  const handleChangeChildsAge = (age) => {
    setState({ ...state, childAge: Object.values(age) });
  };

  const handleSubmit = () => {
    // if (signed) {
    setSend(true);

    const totalPassengers = state.qtAdult + state.qtChild;
    if (totalPassengers > 9) {
      showAlert(
        "warning",
        "Ops! Para grupos acima de 9 pessoas, nosso time de especialistas entra em ação! Entre em contato e descubra opções personalizadas para sua viagem."
      );
      history.push("/plantrip");
      return;
    }

    if (
      state.cidade === "" ||
      state.datas === "" ||
      state.qtRooms === 0 ||
      (state.qtAdult === 0 && state.qtChild === 0)
    ) {
      showAlert("error", "Por favor, preencha todos os campos");
    } else {
      cleanStorageBeforeSearch();
      sessionStorage.setItem("hotelSearch", JSON.stringify(state));
      sessionStorage.setItem("buscaAtual", "Hotel");
      history.push("/busca/resultado-pesquisa");
    }
    // } else {
    //   showAlert('error', 'Efetue o login para realizar a busca');
    // }
  };

  useEffect(() => {
    if (state !== initialState) {
      sessionStorage.setItem("hotelSearch", JSON.stringify(state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const hotel = getSessionStorage("hotelSearch");
    if (hotel) setState(hotel);
  }, []);

  return (
    <S.Wrapper>
      <ChooseChidsAge
        open={openModalChild}
        onClose={() => setOpenModalChild(false)}
        qtdChilds={state?.qtChild}
        setQtChilds={(qt) => setState({ ...state, qtChild: qt })}
        handleChangeChildsAge={handleChangeChildsAge}
      />
      <Container>
        <div className="origem-destino-control">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <Autocomplete
                freeSolo
                disableClearable
                className={classes.root}
                noOptionsText="Não encontrado"
                options={options}
                getOptionLabel={(option) =>
                  option
                    ? `${option.cityName}${
                        option.parentName ? `, ${option.parentName}` : ""
                      }${option.country ? `, ${option.country}` : ""}`
                    : ""
                }
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    changeOptions(value);
                  }
                }}
                onChange={(event, newValue) => {
                  setState({ ...state, cidade: newValue });
                }}
                filterOptions={(x) => x}
                value={state.cidade !== "" ? state.cidade : ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Destino"
                    variant="outlined"
                    fullWidth
                    className={classes.root}
                    error={send && state.cidade === initialState.cidade}
                    helperText={
                      send && state.cidade === initialState.cidade
                        ? "Cidade não pode ser vazia"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <InputDateRange
                icon={Calendar}
                label="Data entrada/saida"
                width={190}
                value={state.datas.abreviada}
                onChange={(e) => setState({ ...state, datas: e })}
                error={send && state.datas === initialState.datas}
                helperText={
                  send && state.datas === initialState.datas
                    ? "Data não pode ser vazia"
                    : ""
                }
                minStartDays={
                  user.campaign.advanceDaysCar
                    ? user.campaign.advanceDaysCar
                    : 7
                }
              />
            </Grid>
            <Grid className="search-divider" item xs={12} sm={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                  <IconedInput
                    value={state.qtAdult}
                    select
                    label="Adultos"
                    width={150}
                    onChange={(e) =>
                      setState({ ...state, qtAdult: e.target.value })
                    }
                    error={send && state.qtAdult === 0 && state.qtChild === 0}
                    helperText={
                      send && state.qtAdult === 0 && state.qtChild === 0
                        ? "Selecione pelo menos um tipo de passageiro"
                        : ""
                    }
                  >
                    {numberOfPeople()}
                  </IconedInput>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <p className="label-input-info">(1 à 17 anos)</p>
                  <IconedInput
                    value={state.qtChild}
                    select
                    label="Crianças"
                    width={150}
                    onChange={(e) => {
                      setState({
                        ...state,
                        qtChild: e.target.value,
                        childAge: [],
                      });
                      setOpenModalChild(true);
                    }}
                  >
                    {numberOfPeople()}
                  </IconedInput>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <IconedInput
                    value={state.qtRooms}
                    select
                    label="Quartos"
                    width={150}
                    onChange={(e) =>
                      setState({ ...state, qtRooms: e.target.value })
                    }
                    error={send && state.qtRooms === 0}
                    helperText={
                      send && state.qtRooms === 0
                        ? "Selecione pelo menos 1 quarto"
                        : ""
                    }
                  >
                    {numberOfRooms()}
                  </IconedInput>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <ThemeButton onClick={handleSubmit} className="submit1">
                    Buscar
                  </ThemeButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </S.Wrapper>
  );
};

export default HotelSearchInputs;
