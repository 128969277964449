/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as S from "./styles";
import Rating from "@mui/material/Rating";
import CheckboxFilter from "../../CheckboxFilter";
import {
  Slider,
  FormControlLabel,
  Switch,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";
import StarRateIcon from "@material-ui/icons/StarRate";
import { useGlobal } from "../../../contexts/globalContext";
import { Skeleton } from "@material-ui/lab";
import { addFeeByDestinoFerias } from "../../../utils/addFeeByDestinoFerias";

const HotelFilter = ({
  changeFilter,
  stays,
  searchState,
  loading,
  error,
  buscaAtual,
}) => {
  // const [state, setState] = useState({ stars: [], mapOrList: false });
  const [state, setState] = useState({ stars: 0, mapOrList: false });
  // const [mapOrList, setMapOrList] = useState(false);

  const { user, signed } = useGlobal();
  const [shortenCoinAlias, setShortenCoinAlias] = useState("Pts");

  const getValuesByUser = (val) => {
    let value = signed
      ? val * user.catalog.catalogCurrencyConfig.conversionRate
      : val;

    if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

    return signed ? Math.ceil(Math.round(value)) : value;
  };

  const [dailyPriceInicial, setDailyPriceInicial] = useState([0, 10000]);

  const [dailyPrice, setDailyPrice] = React.useState([0, 10000]);

  const onChangeFilters = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // const onChangeStars = (stars, checked) => {
  // 	let starsArray = [...state.stars];

  // 	const index = starsArray.indexOf(Number(stars));

  // 	if (index === -1) {
  // 		checked && starsArray.push(Number(stars));
  // 	} else {
  // 		if (!checked) {
  // 			starsArray.splice(index, 1);
  // 		}
  // 	}
  // 	setState({ ...state, stars: [...starsArray] });
  // };

  const onChangeStarsFilters = (stars) => {
    setState({ ...state, stars: stars });
  };

  const changeCheckbox = (obj) => {
    setState({ ...state, [obj.name]: obj.checked });
  };

  useEffect(() => {
    const prices = [
      stays?.sort(
        (a, b) => a?.price.amountAfterTax - b?.price.amountAfterTax
      )[0]?.price.amountAfterTax,
      stays?.sort(
        (a, b) => b?.price.amountAfterTax - a?.price.amountAfterTax
      )[0]?.price.amountAfterTax,
    ];
    setDailyPriceInicial(prices);

    setDailyPrice(prices);
  }, [stays]);

  useEffect(() => {
    setState({ ...state, dailyPrice: dailyPrice });
  }, [dailyPrice]);

  useEffect(() => {
    changeFilter(state);
  }, [state, setState]);

  useEffect(() => {
    setShortenCoinAlias(
      user?.campaign?.campaignConfig?.shortenCoinAlias || "Pts"
    );
  }, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

  const returnArrayOfValuesFilteredTrue = (field) => {
    return stays?.filter((stay) => {
      const matchStars =
        state.stars === 0 ||
        stay.basicPropertyInfo?.award?.rating >= state.stars;
      const matchMeals = stay.roomStays.roomTypes.find((roomType) =>
        roomType.roomRates.find(
          (roomRate) => roomRate.ratePlans?.mealsIncluded?.[field] === true
        )
      );
      return matchStars && matchMeals;
    }).length;
  };

  const returnArrayOfValuesFilteredFalse = (field) => {
    return stays?.filter((stay) => {
      const matchStars =
        state.stars === 0 ||
        stay.basicPropertyInfo?.award?.rating >= state.stars;
      const matchMeals = stay.roomStays.roomTypes.find((roomType) =>
        roomType.roomRates.find(
          (roomRate) => roomRate.ratePlans?.mealsIncluded?.[field] === false
        )
      );
      return matchStars && matchMeals;
    }).length;
  };

  const mealsIncludedBreakfast = returnArrayOfValuesFilteredTrue("breakfast");

  const mealsIncludedLunch = returnArrayOfValuesFilteredTrue("lunch");

  const mealsIncludedDinner = returnArrayOfValuesFilteredTrue("dinner");

  const noBreakfastIncluded = returnArrayOfValuesFilteredFalse("breakfast");

  const mealsIncluded = [
    {
      name: "breakfast",
      label: "Café da manhã",
      value: mealsIncludedBreakfast,
      disabled: mealsIncludedBreakfast > 0 ? false : true,
      currency: true,
    },
    {
      name: "lunch",
      label: "Almoço",
      value: mealsIncludedLunch,
      disabled: mealsIncludedLunch > 0 ? false : true,
      currency: true,
    },
    {
      name: "dinner",
      label: "Janta",
      value: mealsIncludedDinner,
      disabled: mealsIncludedDinner > 0 ? false : true,
      currency: true,
    },
  ];

  const breakfastIncluded = [
    {
      name: "breakfast",
      label: "Com café da manhã",
      value: mealsIncludedBreakfast,
      disabled: mealsIncludedBreakfast > 0 ? false : true,
      currency: true,
    },
    {
      name: "noBreakfast",
      label: "Sem café da manhã",
      value: noBreakfastIncluded,
      disabled: noBreakfastIncluded > 0 ? false : true,
      currency: true,
    },
  ];

  const fiveStars = stays?.filter(
    (s) => s.basicPropertyInfo?.award?.rating === 5
  ).length;
  const fourStars = stays?.filter(
    (s) => s.basicPropertyInfo?.award?.rating === 4
  ).length;
  const threeStars = stays?.filter(
    (s) => s.basicPropertyInfo?.award?.rating === 3
  ).length;
  const twoStars = stays?.filter(
    (s) => s.basicPropertyInfo?.award?.rating === 2
  ).length;
  const oneStars = stays?.filter(
    (s) => s.basicPropertyInfo?.award?.rating === 1
  ).length;

  const starsRating = [
    {
      name: "stars",
      starsValue: "5",
      label: (
        <>
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
        </>
      ),
      value: fiveStars,
      disabled: fiveStars > 0 ? false : true,
      currency: true,
    },
    {
      name: "stars",
      starsValue: "4",
      label: (
        <>
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
        </>
      ),
      value: fourStars,
      disabled: fourStars > 0 ? false : true,
      currency: true,
    },
    {
      name: "stars",
      starsValue: "3",
      label: (
        <>
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
        </>
      ),
      value: threeStars,
      disabled: threeStars > 0 ? false : true,
      currency: true,
    },
    {
      name: "stars",
      starsValue: "2",
      label: (
        <>
          <StarRateIcon />
          <StarRateIcon />
        </>
      ),
      value: twoStars,
      disabled: twoStars > 0 ? false : true,
      currency: true,
    },
    {
      name: "stars",
      starsValue: "1",
      label: <StarRateIcon />,
      value: oneStars,
      disabled: oneStars > 0 ? false : true,
      currency: true,
    },
  ];
  return loading ? (
    <Skeleton variant="rect" width={"100%"} height={1000} />
  ) : (
    <div>
      {!error && (
        <S.Wrapper>
          <div className="filter-group">
            <S.FilterTitle>Visualizar como:</S.FilterTitle>
            <FormControlLabel
              control={
                <Switch
                  checked={state.mapOrList}
                  onChange={() =>
                    setState({ ...state, mapOrList: !state.mapOrList })
                  }
                />
              }
              label={
                <S.ThinText>{state.mapOrList ? "Mapa" : "Lista"}</S.ThinText>
              }
            />
          </div>
          <div className="filter-group">
            <S.FilterTitle>Nome do hotel</S.FilterTitle>
            <FormControl variant="outlined" className="form-control">
              <OutlinedInput
                id="outlined-input-iconed"
                className="outlined-input-iconed"
                placeholder="Filtrar por nome de hotel..."
                type="text"
                name="hotelName"
                onChange={(e) => onChangeFilters(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="default">
                      <Search size={24} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          {/* <div className='filter-group'>
          <S.FilterTitle>O essencial da viagem de trabalho</S.FilterTitle>
          {essentialForBusinessTravel.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              name={cbx.name}
              disabled={cbx.disabled}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}
          {/* <div className='filter-group'>
          <S.FilterTitle>Amenidades Populares</S.FilterTitle>
          {popularAmenities.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}

          {/* <div className='filter-group'>
          <S.FilterTitle>Exclusivo para Expense Mobi</S.FilterTitle>
          {expenseMobiExclusive.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}
          <div className="filter-group">
            {/* <S.FilterTitle>Classificação por estrelas</S.FilterTitle>
						{starsRating.map((cbx, index) => (
							<CheckboxFilter
								key={index}
								label={cbx.label}
								disabled={cbx.disabled}
								name={cbx.name}
								valueLabel={cbx.value}
								onChange={(e) => onChangeStars(cbx.starsValue, e.checked)}
							/>
						))} */}
            <S.FilterTitle>Estrelas maiores que: </S.FilterTitle>
            <Rating
              name="simple-controlled"
              value={state?.stars}
              precision={0.5}
              onChange={(event, starsValue) => {
                onChangeStarsFilters(starsValue);
              }}
            />
          </div>
          <div className="filter-group">
            <S.FilterTitle>Preço </S.FilterTitle>
            <S.ThinText>
              {signed &&
              !user?.guest &&
              !user?.campaign?.disablePointActivity ? (
                <span>
                  {getValuesByUser(dailyPrice[0])} {shortenCoinAlias} -{" "}
                  {getValuesByUser(dailyPrice[1])} {shortenCoinAlias}
                </span>
              ) : (
                <>
                  {" "}
                  <CurrencyFormat
                    value={dailyPrice[0]}
                    displayType={"text"}
                    decimalSeparator=","
                    decimalScale={2}
                    prefix={"R$ "}
                  />{" "}
                  -{" "}
                  <CurrencyFormat
                    value={dailyPrice[1]}
                    displayType={"text"}
                    decimalSeparator=","
                    decimalScale={2}
                    prefix={"R$ "}
                  />
                </>
              )}
            </S.ThinText>

            <Slider
              value={dailyPrice}
              onChange={(event, newValue) => setDailyPrice(newValue)}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              min={dailyPriceInicial[0]}
              max={dailyPriceInicial[1]}
            />
          </div>
          <div className="filter-group">
            <S.FilterTitle>Refeições inclusas</S.FilterTitle>
            {mealsIncluded.map((cbx, index) => (
              <CheckboxFilter
                key={index}
                label={cbx.label}
                name={cbx.name}
                disabled={cbx.disabled}
                valueLabel={cbx.value}
                onChange={(obj) => changeCheckbox(obj)}
              />
            ))}
          </div>

          <div className="filter-group">
            <S.FilterTitle>Café da manhã</S.FilterTitle>
            {breakfastIncluded.map((cbx, index) => (
              <CheckboxFilter
                key={index}
                label={cbx.label}
                name={cbx.name}
                disabled={cbx.disabled}
                valueLabel={cbx.value}
                onChange={(obj) => changeCheckbox(obj)}
              />
            ))}
          </div>
          {/* <div className='filter-group'>
          <S.FilterTitle>Cadeias de fidelidade</S.FilterTitle>
          {loyaltyChains.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}
          {/* <div className='filter-group'>
          <S.FilterTitle>Pontuação da revisão</S.FilterTitle>
          {reviewScore.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}
          {/* <div className='filter-group'>
          <S.FilterTitle>Distritos</S.FilterTitle>
          {districts.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}
          {/* <div className='filter-group'>
          <S.FilterTitle>Tipo de acomodação</S.FilterTitle>
          {accommodationType.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCheckbox(obj)}
            />
          ))}
        </div> */}
        </S.Wrapper>
      )}
    </div>
  );
};

export default HotelFilter;
