/* eslint-disable eqeqeq */
import React, { useEffect, useState, useLayoutEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import * as S from "./styles";

import VitrineRepository from "../../../repositories/vitrineRepository";
import { pt } from "react-date-range/dist/locale";
import { format } from "date-fns";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import { useHistory } from "react-router";
import { addFeeByDestinoFerias } from "../../../utils/addFeeByDestinoFerias";

const ShowCaseLine = ({ user, data }) => {
	const vitrineRepository = new VitrineRepository();
	const [coinAlias, setCoinAlias] = useState("Pontos");

	let history = useHistory();

	const [images, setImages] = useState([]);

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || "Pontos");
	}, [user?.campaign?.campaignConfig?.coinAlias]);

	useLayoutEffect(() => {
		data?.images?.map((d) => getImageByCodes(data.id, d.imageId));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const getValuesByUser = (val, type) => {
		let value = val * user?.catalog?.catalogCurrencyConfig?.conversionRate;

		if (user.campaign) value = addFeeByDestinoFerias(value, type);

		return Math.ceil(Math.round(value));
	};

	const getImageByCodes = async (idVitrine, imageId) => {
		vitrineRepository.getVitrineIcone(idVitrine, imageId).then((res) => {
			setImages((oldState) => [...oldState, { imageId, url: res }]);
		});
	};

	const getWidthVitrines = (length) => {
		switch (length) {
			case 2:
				return 6;
			case 1:
				return 12;
			case 3:
				return 4;
			case 4:
				return 3;
			default:
				return 3;
		}
	};

	const setDatas = (datas) => {
		let weekDayStartDate = format(datas.startDate, "EEEE dd MMMM yyyy", {
			locale: pt,
		});
		let weekDayEndDate = format(datas.endDate, "EEEE dd MMMM yyyy", {
			locale: pt,
		});
		let startDate = new Date(datas.startDate);
		let endDate = new Date(datas.endDate);
		let dataIda = format(datas.startDate, "dd/MM/yyyy");
		let dataVolta = format(datas.endDate, "dd/MM/yyyy");
		let abreviada = `${format(datas.startDate, "dd/MM")} - ${format(datas.endDate, "dd/MM")}`;

		return {
			dataIda,
			dataVolta,
			abreviada,
			weekDayStartDate,
			weekDayEndDate,
			startDate,
			endDate,
		};
	};

	const mountRequestInStorage = (vitrine) => {
		if (vitrine.type) {
			switch (vitrine.type) {
				case "FLIGHT":
					cleanStorageBeforeSearch();
					sessionStorage.setItem(
						"flySearch",
						JSON.stringify({
							origem: {
								iata: vitrine.airportOrigin,
								airportName: vitrine.airportOriginName,
								description: vitrine.airportOriginName,
							},
							destino: {
								iata: vitrine.airportDestination,
								airportName: vitrine.airportDestinationName,
								description: vitrine.airportDestinationName,
							},
							datas: setDatas({
								startDate: vitrine.startDate,
								endDate: vitrine.endDate,
							}),
							adultos: 1,
							criancas: 0,
							bebes: 0,
							idavolta: true,
							tarifasBagagem: false,
							apenasVoosDiretos: false,
						})
					);
					sessionStorage.setItem("buscaAtual", "Aéreo");
					history.push("/busca/resultado-pesquisa");
					break;
				case "HOTEL":
					cleanStorageBeforeSearch();
					sessionStorage.setItem(
						"hotelSearch",
						JSON.stringify({
							cidade: {
								code: vitrine.cityCode,
								cityName: vitrine.cityName,
								isoCode: vitrine.country,
							},
							datas: setDatas({
								startDate: vitrine.startDate,
								endDate: vitrine.endDate,
							}),
							qtAdult: 1,
							qtChild: 0,
							qtRooms: 1,
						})
					);
					sessionStorage.setItem("buscaAtual", "Hotel");
					history.push("/busca/resultado-pesquisa");
					break;
				case "CAR":
					cleanStorageBeforeSearch();
					sessionStorage.setItem(
						"carSearch",
						JSON.stringify({
							cidadeRetirada: {
								countryIso: vitrine.country,
								iata: vitrine.airportOrigin,
								description: vitrine.airportOriginName,
							},
							localRetiradaName: vitrine.locationName,
							localRetirada: {
								rental: {
									name: vitrine.locationName,
								},
								address: {
									address: vitrine.locationName,
								},
								locationCode: vitrine.location,
							},
							horaRetirada: "09:00",
							cidadeDevolucao: "",
							localDevolucao: "",
							horaDevolucao: "09:00",
							datas: setDatas({
								startDate: vitrine.startDate,
								endDate: vitrine.endDate,
							}),
							mesmoLocal: true,
						})
					);
					sessionStorage.setItem("buscaAtual", "Carro");
					history.push("/busca/resultado-pesquisa");
					break;
				case "EXPERIENCE":
					cleanStorageBeforeSearch();
					sessionStorage.setItem(
						"experienceSearch",
						JSON.stringify({
							cidade: {
								id: vitrine.cityCode,
								cityName: vitrine.cityName,
							},
							datas: setDatas({
								startDate: vitrine.startDate,
								endDate: vitrine.endDate,
							}),
						})
					);
					sessionStorage.setItem("buscaAtual", "Experiencias");
					history.push("/busca/resultado-pesquisa");
					break;
				case "BUS":
					cleanStorageBeforeSearch();
					sessionStorage.setItem(
						"busSearch",
						JSON.stringify({
							origem: {
								iata: vitrine.airportOrigin,
								airportName: vitrine.airportOriginName,
								description: vitrine.airportOriginName,
							},
							destino: {
								iata: vitrine.airportDestination,
								airportName: vitrine.airportDestinationName,
								description: vitrine.airportDestinationName,
							},
							datas: setDatas({
								startDate: vitrine.startDate,
								endDate: vitrine.endDate,
							}),
							adultos: 1,
							idavolta: true,
						})
					);
					sessionStorage.setItem("buscaAtual", "Rodoviário");
					history.push("/busca/resultado-pesquisa");
					break;
				default:
					break;
			}
		} else if (vitrine.link && vitrine.link.trim() !== "") {
			window.open(vitrine.link, "_blank");
		} else {
			return;
		}
	};

	return (
		<S.Wrapper>
			<Typography variant="h5">
				<b>{data?.name}</b>
			</Typography>
			<Typography>{data?.description}</Typography>
			<Grid container spacing={2}>
				{data?.images?.map((i, index) => (
					<Grid item xs={12} sm={getWidthVitrines(data?.images.length)} key={i.imageId}>
						<Box sx={{ cursor: "pointer" }} display="flex" flexDirection="column" gridGap={5} onClick={() => mountRequestInStorage(i)}>
							<img alt="imagem" src={images.find((x) => x.imageId == i.imageId)?.url} />
							{i?.name && (
								<Typography variant="h6" style={{ fontSize: "1.06rem" }}>
									<b>{i?.name}</b>
								</Typography>
							)}
							{i?.description && <Typography variant="h6" style={{ fontSize: "1.06rem" }}>{i?.description}</Typography>}
							{i.price && (
								<>
									{i?.originalPrice ? (
										<>
											<Typography variant="span">
												de{" "}
												<s>
													{getValuesByUser(i?.originalPrice)} {coinAlias}
												</s>
											</Typography>
											<Typography variant="h5">
												{" "}
												por {getValuesByUser(i?.price)} {coinAlias}
											</Typography>{" "}
										</>
									) : (
										<>
											<Typography variant="caption">A partir de</Typography>
											<Typography variant="h5">
												{getValuesByUser(i?.price, i.type)} {coinAlias}
											</Typography>
										</>
									)}
								</>
							)}
						</Box>
					</Grid>
				))}
			</Grid>
		</S.Wrapper>
	);
};

export default ShowCaseLine;
