import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { Grid } from "@material-ui/core";
import TimerIcon from "@mui/icons-material/Timer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useHistory } from "react-router-dom";
import cleanStorageBeforeSearch from "../../../../utils/cleanStorageBeforeSearch";
import CheckAvailabilityModal from '../../../../components/CheckAvailabilityModal';
import { useGlobal } from '../../../../contexts/globalContext';

const EntertainmentCard = ({ activity }) => {
	const { user } = useGlobal();
	const [isOpenCheckAvailabilityModal, setIsOpenCheckAvailabilityModal] =
		useState(false);
	const [selectedModalItem, setSelectedModalItem] = useState();
	const [adultPrice, setAdultPrice] = useState(null);
	const [isPointPrice, setIsPointPrice] = useState(true);
	let history = useHistory();
	const [showFullTextDescription, setShowFullTextDescription] = useState(false);
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const showFullTextTitle = false;

	const LIMITCHARS = 100;

	const isTitleLong = activity.name.length > LIMITCHARS;
	const displayedTitle = showFullTextTitle
		? activity.name
		: activity.name.substring(0, LIMITCHARS);

	const isDescriptionLong = activity.description.length > LIMITCHARS;
	const displayedDescription = showFullTextDescription
		? activity.description
		: activity.description.substring(0, LIMITCHARS);

	const handleCheckAvailabilityModal = () =>
		setIsOpenCheckAvailabilityModal((prev) => !prev);

	const toggleText = () => {
		setShowFullTextDescription((prev) => !prev);
	};
	useEffect(() => {
		let price = null;
		if (user?.campaign?.disablePointActivity) {
			setIsPointPrice(false);
			if (activity.ticketPassengerRate) {
				price = activity.price;
			} else if (activity.tourPassengerRate) {
				price = activity.price;
			} else if (activity.transferPassengerRate) {
				price = activity.price;
			}
		} else {
			setIsPointPrice(true);
			if (activity.ticketPassengerRate) {
				price = activity.pointsPrice;
			} else if (activity.tourPassengerRate) {
				price = activity.pointsPrice;
			} else if (activity.transferPassengerRate) {
				price = activity.pointsPrice;
			}
		}

		if (price !== null) {
			setAdultPrice(price);
		}
	}, [activity]);

	const handleSelectActivity = () => {
		let passengerRate = null;

		if (activity.ticketPassengerRate) {
			passengerRate = activity.ticketPassengerRate;
		} else if (activity.tourPassengerRate) {
			passengerRate = activity.tourPassengerRate;
		} else if (activity.transferPassengerRate) {
			passengerRate = activity.transferPassengerRate;
		}

		const currentData =
			JSON.parse(sessionStorage.getItem('entertainmentSearch')) || {};

		cleanStorageBeforeSearch();

		const updatedData = {
			...currentData,
			selectedCity: activity.locationFull,
		};

		sessionStorage.setItem('entertainmentSearch', JSON.stringify(updatedData));

		sessionStorage.setItem('buscaAtual', 'Entretenimento');

		sessionStorage.setItem(
			'@EntertainmentSelected',
			JSON.stringify({
				activity,
				passengerRate,
			})
		);
		setSelectedModalItem({ activity, passengerRate });
		setIsOpenCheckAvailabilityModal(true);
		// history.push('/busca/entertainment-details');
	};

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<>
			{selectedModalItem && (
				<CheckAvailabilityModal
					activity={selectedModalItem}
					open={isOpenCheckAvailabilityModal}
					onClose={handleCheckAvailabilityModal}
				/>
			)}
			<S.CardContent className='cardContent'>
				<Grid justifyContent='center' container className='container_img'>
					<S.GridItemFlex item className='item_img'>
						<img
							className='img'
							src={activity.pictureUrl}
							alt={activity.name}
							onClick={handleSelectActivity}
						/>
					</S.GridItemFlex>
				</Grid>
				<Grid container direction='row' className='container_details'>
					<S.GridItemFlex item className='top'>
						<span className='title' onClick={handleSelectActivity}>
							{displayedTitle}
							{isTitleLong && (
								<span className='plus'>{showFullTextTitle ? '' : '...'}</span>
							)}
						</span>
						<span className='type'>
							{(() => {
								if (activity.activityId.startsWith('4')) {
									return 'Transfer';
								} else if (activity.activityId.startsWith('5')) {
									return 'Ingresso';
								} else if (activity.activityId.startsWith('6')) {
									return 'Passeio';
								}
							})()}
						</span>
						<span className='location'>{activity.location}</span>
						{activity.daysBeforeCancellationPolicy > 0 && (
							<div className='separator'>
								<div className='cancelation tooltip'>
									Cancelamento grátis
									<div className='tooltiptext'>
										<h3>
											Cancele sem multa até {activity.daysBeforeCancellationPolicy} dias
											antes da atividade.
										</h3>
										<p>
											Em menos tempo ou em caso de não comparecimento no dia da atividade
											não será oferecido reembolso
										</p>
									</div>
								</div>
							</div>
						)}
					</S.GridItemFlex>
					<S.GridItemFlex item className='mid'>
						<span className='description'>
							{displayedDescription}
							{isDescriptionLong && (
								<span className='plus' onClick={toggleText}>
									{showFullTextDescription ? ' ver menos' : '... ver mais'}
								</span>
							)}
						</span>
					</S.GridItemFlex>
					<S.GridItemFlex item className='bottom'>
						<div className='group'>
							<div className='left'>
								<div className='weekDays'>
									<TimerIcon className='icon' alt='weekDays-icon' />
									<span className='text'>{activity.weekDays}</span>
									<span className='text' hidden>
										{activity.weekDaysList}
									</span>
								</div>
								<div className='duration'>
									{activity.duration > 0 ? (
										<CalendarMonthIcon className='icon' alt='calendar-icon' />
									) : (
										''
									)}
									<span className='text'>
										{activity.duration > 0 ? 'Duração: ' + activity.duration + 'h' : ''}
									</span>
								</div>
							</div>
							<span className='price_label'>A partir de</span>
							{isPointPrice ? (
								<span className='price_text'>
									{adultPrice || 'N/A'} {shortenCoinAlias}
								</span>
							) : (
								<span className='price_text'>
									{shortenCoinAlias}{' '}
									{adultPrice?.toLocaleString('pt-br', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || 'N/A'}
								</span>
							)}
						</div>
					</S.GridItemFlex>
				</Grid>
			</S.CardContent>
		</>
	);
};

export default EntertainmentCard;
